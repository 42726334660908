import { AdministrationYearChangeUpdateVOPeriodEndpoint } from '@core/schemas/endpoint/schema.endpoint.administration';
import { callEndpoint } from '@frontend/common/lib/callEndpoint';
import { prettifyTimestamp } from '@frontend/common/lib/common.util';
import { ConfigContext } from '@frontend/common/lib/contexts';
import { showSuccessToast } from '@frontend/common/lib/functions';
import { FInput } from '@frontend/form/components/FInput';
import { Button, DateRangePickerNative } from '@lego/klik-ui';
import { useContext, useState } from 'react';

export function YearChangeUpdateVOPeriod() {
  const [period, setPeriod] = useState<[string, string]>(['', '']);
  const [loading, setLoading] = useState(false);

  const { config, refresh, loading: configLoading } = useContext(ConfigContext);

  async function updatePeriod() {
    setLoading(true);
    const [err] = await callEndpoint({
      endpoint: AdministrationYearChangeUpdateVOPeriodEndpoint,
      input: { start: new Date(period[0]).toISOString(), end: new Date(period[1]).toISOString() },
      errorHandling: { header: 'Updating VO period' },
    });
    setLoading(false);

    if (err) {
      return;
    }

    refresh();
    setPeriod(['', '']);
    showSuccessToast('Updating VO period', 'Successfully updated VO period');
  }

  return (
    <div>
      <FInput
        label="Period start"
        value={prettifyTimestamp(config.vo_period_start, { timeMode: 'HIDE' })}
        align="start"
      />
      <FInput
        label="Period end"
        value={prettifyTimestamp(config.vo_period_end, { timeMode: 'HIDE' })}
        align="start"
      />
      <div style={{ marginTop: 24, width: 400 }}>
        <DateRangePickerNative value={period} onChange={(v) => setPeriod(v as [string, string])}>
          <DateRangePickerNative.DatePicker label="Period start" size="sm" />
          <DateRangePickerNative.DatePicker label="Period end" size="sm" />
        </DateRangePickerNative>
      </div>
      <Button
        size="sm"
        style={{ marginTop: 16 }}
        disabled={!period[0] || !period[1] || period[1] < period[0]}
        isLoading={loading || configLoading}
        onClick={updatePeriod}
      >
        Update
      </Button>
    </div>
  );
}
