import { z } from 'zod';
import { RevisionErrorOrigin } from './schema.common';

export const IsoDateSchema = z.string().refine((value) => {
  const date = new Date(value);
  return !isNaN(date.getTime()) && date.toISOString() === value;
}, 'Invalid ISO 8601 date string');

export function revisionErrorOriginToPretty(revisionErrorOrigin: RevisionErrorOrigin) {
  if (revisionErrorOrigin === RevisionErrorOrigin.Empty) {
    return '';
  }

  if (revisionErrorOrigin === RevisionErrorOrigin.BICost) {
    return 'BI cost';
  }

  return revisionErrorOrigin
    .split('_')
    .map((v, i) => (i === 0 ? v[0] + v.slice(1).toLowerCase() : v.toLowerCase()))
    .join(' ');
}

export function notEmpty(): [(data: object) => boolean, object] {
  return [
    (data: object) => Object.keys(data).length > 0,
    {
      message: 'At least one key must be provided',
    },
  ];
}
