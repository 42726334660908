import { createContext } from 'react';
import { ModalInformation } from './models';
import { OmitDBRecord } from '@core/schemas/db/schema.db.common';
import { DBUserSettings } from '@core/schemas/db/schema.db.userSettings';
import { DBConfig } from '@core/schemas/db/schema.db.config';
import { VirtualGroupName } from '@core/const/const.VIRTUAL_GROUPS';
import { now } from '@core/util/util.timestamp';

/* eslint-disable @typescript-eslint/no-empty-function */
export const ConfigContext = createContext<{
  config: OmitDBRecord<DBConfig>;
  refresh: () => void;
  loading: boolean;
}>({
  refresh: () => {},
  config: {
    developer_contacts: JSON.stringify([]),
    fmc_active_year: 0,
    alternative_bom_no_max_inclusive: 0,
    bom_status_min_inclusive: 0,
    bom_status_max_inclusive: 0,
    block_iserver_bom: false,
    block_iserver_item: false,
    vo_period_start: now(),
    vo_period_end: now(),
  },
  loading: false,
});

export const UserDataContext = createContext<{
  virtualGroups: VirtualGroupName[];
  showReport: boolean;
  userData: OmitDBRecord<DBUserSettings> | undefined;
  email: string;
  domainUsername: string;
  refresh: () => void;
  isRefreshing: boolean;
  getName: (email: string | undefined) => string;
}>({
  virtualGroups: [],
  showReport: false,
  userData: undefined,
  email: '',
  domainUsername: '',
  refresh: () => {},
  isRefreshing: false,
  getName: () => '',
});

export const HiddenColumnsContext = createContext<{
  getHiddenColumnIdsForId: (tableId: string) => string[];
  refreshHiddenColumns: () => void;
}>({ getHiddenColumnIdsForId: () => [], refreshHiddenColumns: () => {} });

export const ModalContext = createContext<(modalInformation: ModalInformation) => void>(() => {});

export const LoadingOverlayContext = createContext<{
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
}>({ isLoading: false, setIsLoading: () => {} });

export const ProductIndexContext = createContext<{
  setProductIndex: (productId: number, revision: 'new' | number | undefined) => void;
  setProductId: (productId: number) => void;
  setRevision: (revision: 'new' | number | undefined) => void;
  getProductIndex: () => { productId: number | undefined; revision: 'new' | number | undefined };
  clearProductIndex: () => void;
  suggestedProductId: number | undefined;
  setSuggestedProductId: (productId: number | undefined) => void;
  suggestions: string[];
  addSuggestion(suggestion: string): void;
}>({
  setProductIndex: () => {},
  setProductId: () => {},
  setRevision: () => {},
  getProductIndex: () => ({ productId: undefined, revision: undefined }),
  clearProductIndex: () => {},
  suggestedProductId: undefined,
  setSuggestedProductId: () => {},
  suggestions: [],
  addSuggestion: () => {},
});
/* eslint-disable @typescript-eslint/no-empty-function */
