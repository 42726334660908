import '@core/openapi/openapi.register.extendZodWithOpenApi';
import React from 'react';
import 'regenerator-runtime/runtime';
import App from './App';
import './main.css';
import { BrowserRouter } from 'react-router-dom';
import { createRoot } from 'react-dom/client';
import { createStandaloneToast, KlikProvider } from '@lego/klik-ui';
import { InteractionType } from '@azure/msal-browser';
import { MsalAuthenticationTemplate, MsalProvider } from '@azure/msal-react';
import { authProvider } from './common/lib/authProvider';

const container = document.getElementById('root');
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);

const { ToastContainer } = createStandaloneToast();

root.render(
  <React.StrictMode>
    <MsalProvider instance={authProvider}>
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}
        authenticationRequest={{
          scopes: [import.meta.env.FE_API_SCOPE],
        }}
      >
        <BrowserRouter>
          <KlikProvider includeFont>
            <App />
            <ToastContainer />
          </KlikProvider>
        </BrowserRouter>
      </MsalAuthenticationTemplate>
    </MsalProvider>
  </React.StrictMode>,
);
