import { ViewName } from '@core/schemas/schema.common';

export function prettyViewName(viewName: ViewName) {
  if (viewName === 'elements') {
    return 'elements';
  }
  if (viewName === 'packaging') {
    return 'packaging';
  }
  if (viewName === 'bi') {
    return 'instructions';
  }
  return 'final costs';
}
