import { Table } from '@frontend/table/table';
import { AMLogsForGroupColumns, getVirtualGroupMemberColumns } from '../administration.columns.am';
import { Button } from '@lego/klik-ui-button';
import { useCallback, useContext, useMemo, useState } from 'react';
import { FInput } from '@frontend/form/components/FInput';
import { NCConfirmModal } from '@frontend/common/components/NCConfirmModal';
import { UserDataContext } from '@frontend/common/lib/contexts';
import { useEndpoint } from '@frontend/common/lib/hooks/useEndpoint';
import {
  AMAddMembersToVirtualGroupsEndpoint,
  AMGetLogsForVirtualGroupEndpoint,
  AMGetVirtualGroupMembersEndpoint,
  AMRemoveVirtualMembersEndpoint,
} from '@core/schemas/endpoint/schema.endpoint.am';
import { Unpacked } from '@core/util/util.typing';
import { VIRTUAL_GROUPS } from '@core/const/const.VIRTUAL_GROUPS';
import { callEndpoint } from '@frontend/common/lib/callEndpoint';
import { isDev, showSuccessToast } from '@frontend/common/lib/functions';
import { primaryDeveloperContact } from '@frontend/App';
import { REGEX_EMAIL } from '@core/types/types.regex';

interface VirtualGroupModalContentProps {
  group: Unpacked<typeof VIRTUAL_GROUPS>;
  emailLov: string[];
}

export function VirtualGroupModalContent(props: VirtualGroupModalContentProps) {
  const {
    data,
    loading,
    queryFunction: fetchMembers,
  } = useEndpoint({
    endpoint: AMGetVirtualGroupMembersEndpoint,
    input: { groupName: props.group.name },
    errorHandling: { header: 'Fetching group members' },
  });

  const {
    data: logs,
    loading: logsLoading,
    queryFunction: fetchLogs,
  } = useEndpoint({
    endpoint: AMGetLogsForVirtualGroupEndpoint,
    input: { groupName: props.group.name },
    errorHandling: { header: 'Fetching logs' },
  });

  const [email, setEmail] = useState('');
  const [isAddingEmail, setIsAddingEmail] = useState(false);
  const [emailToDelete, setEmailToDelete] = useState('');
  const [isWorking, setIsWorking] = useState(false);

  const { getName } = useContext(UserDataContext);

  const addMember = useCallback(
    async (email: string) => {
      setIsAddingEmail(true);
      const [err] = await callEndpoint({
        endpoint: AMAddMembersToVirtualGroupsEndpoint,
        input: { emails: [email], groupNames: [props.group.name] },
        errorHandling: { header: 'Adding user to group' },
      });
      setIsAddingEmail(false);

      if (err) {
        return;
      }

      setEmail('');
      showSuccessToast(
        'Added user to group',
        `Successfully added ${getName(email)} to ${props.group.name}`,
      );
      fetchLogs();
      fetchMembers();
    },
    [getName, props.group.name, fetchLogs, fetchMembers],
  );

  const removeMember = useCallback(
    async (email: string) => {
      setIsWorking(true);
      const [err] = await callEndpoint({
        endpoint: AMRemoveVirtualMembersEndpoint,
        input: { emails: [email], groupName: props.group.name },
        errorHandling: { header: 'Removing member from group' },
      });
      setIsWorking(false);

      if (err) {
        return;
      }

      showSuccessToast(
        'Removed member from group',
        `Successfully removed ${getName(email)} from ${props.group.name}`,
      );
      setEmailToDelete('');
      fetchMembers();
      fetchLogs();
    },

    [getName, props.group.name, fetchLogs, fetchMembers],
  );

  const columns = useMemo(
    () =>
      getVirtualGroupMemberColumns({
        onRemoveMember: setEmailToDelete,
        isWorking,
      }),
    [isWorking],
  );

  const logsColumns = useMemo(() => AMLogsForGroupColumns(getName), [getName]);

  return (
    <div>
      {emailToDelete && (
        <NCConfirmModal
          isOpen={!!emailToDelete}
          description={`Are you sure you want to remove ${getName(emailToDelete)} from ${
            props.group.name
          }?`}
          header="Remove member"
          onCancel={() => setEmailToDelete('')}
          onConfirm={async () => await removeMember(emailToDelete)}
          variant="warning"
        />
      )}
      <div style={{ marginBottom: 8, fontSize: 18 }}>Members</div>
      <Table
        id="administration_group-members"
        rows={data || []}
        rowKey="email"
        columns={columns}
        isRefreshing={loading || isWorking}
        itemType="member"
        itemsPerPage={10}
        fixedHeight={297.5}
      />
      <div style={{ marginTop: data && data.length > 0 ? 0 : 24, marginBottom: 8 }}>
        {isDev() ? (
          <div style={{ fontSize: 14 }}>
            Contact the developer for adding/removing people from groups
            {primaryDeveloperContact ? `: ${primaryDeveloperContact}` : ''}
          </div>
        ) : (
          <>
            <div style={{ fontSize: 16 }}>Add member by email</div>
            <div style={{ display: 'flex', columnGap: 8, alignItems: 'end' }}>
              <FInput
                value={email}
                placeholder="Email"
                onChange={setEmail}
                editable
                suggestions={props.emailLov}
                align="start"
                isInvalid={!!email && !REGEX_EMAIL.test(email)}
                inputStyle={{ width: 340 }}
                onEnter={() => {
                  if (!email) {
                    return;
                  }
                  addMember(email);
                }}
              />
              <Button
                size="sm"
                disabled={!email || !REGEX_EMAIL.test(email)}
                onClick={() => addMember(email)}
                isLoading={isAddingEmail}
                className="button-green"
              >
                Add
              </Button>
            </div>
          </>
        )}
      </div>
      <div style={{ marginBottom: 8, marginTop: 48 }}>Logs</div>
      <Table
        id="administration_your-ad-groups-logs"
        rowKey="SK"
        rows={logs || []}
        columns={logsColumns}
        isRefreshing={logsLoading}
        itemsPerPage={10}
        fixedHeight={297.5}
        itemType="log"
      />
    </div>
  );
}
