import { Route, Routes, useLocation, useSearchParams, useNavigate } from 'react-router-dom';
import { LF_LAST_VISITED, pageRoutes, allRoutes } from '@frontend/common/lib/definitions';
import { BIPage } from '@frontend/bi/components/BiPage';
import { useCallback, useEffect, useState } from 'react';
import { ModalInformation } from './common/lib/models';
import {
  ConfigContext,
  HiddenColumnsContext,
  UserDataContext,
  LoadingOverlayContext,
  ModalContext,
  ProductIndexContext,
} from './common/lib/contexts';
import * as localforage from 'localforage';
import { ElementsPage } from './elements/components/ElementsPage';
import { NCMessageModal } from './common/components/NCMessageModal';
import { NotFoundPage } from './common/components/NotFoundPage';
import { FinalCostsPage } from './final-costs/components/FinalCostsPage';
import { SettingsPage } from './settings/components/SettingsPage';
import { LoadingOverlay } from './common/components/LoadingOverlay';
import { NavigationBar } from './common/components/Navbar';
import { PackagingPage } from './packaging/components/PackagingPage';
import { AdministrationPage } from './administration/components/AdministrationPage';
import { REGEX_NONNEGATIVE_INTEGER } from '@core/types/types.regex';
import { useEndpoint } from '@frontend/common/lib/hooks/useEndpoint';
import {
  EmailNameMapEndpoint,
  UserMeEndpoint,
} from '@core/schemas/endpoint/schema.endpoint.userSettings';
import { ConfigGetEndpoint } from '@core/schemas/endpoint/schema.endpoint.config';
import { HiddenColumnsGetEndpoint } from '@core/schemas/endpoint/schema.endpoint.hiddenColumns';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorBoundaryContent } from './common/components/ErrorBoundaryContent';
import { CommonReportPage } from './reporting/components/CommonReportPage';
import {
  ReportBIGetEndpoint,
  ReportBILBXGetEndpoint,
  ReportElementsCostsGetEndpoint,
  ReportElementsErrorsGetEndpoint,
  ReportFinalCostsGetEndpoint,
  ReportPackagingGetEndpoint,
} from '@core/schemas/endpoint/schema.endpoint.reporting';
import { CostingStatusReportPage } from './reporting/components/CostingStatusReportPage';
import { useSuggestions } from './common/lib/hooks/useSuggestions';
import { isDev, isPathAccesible } from './common/lib/functions';
import { prettifyUsername } from './common/lib/common.util';
import { getMsGraphToken } from './common/lib/authProvider';
import { VirtualGroupName } from '@core/const/const.VIRTUAL_GROUPS';
import { WelcomePage } from './WelcomePage';
import { now } from '@core/util/util.timestamp';

// Used to avoid having to pass this variable to all error notifications
export let primaryDeveloperContact = '';

function App() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [activeModal, setActiveModal] = useState<ModalInformation>();
  const [loadingOverlayOn, setLoadingOverlayOn] = useState(false);
  const [domainUsername, setDomainUsername] = useState('');
  const {
    data: configData,
    queryFunction: refreshConfig,
    loading: configLoading,
  } = useEndpoint({
    endpoint: ConfigGetEndpoint,
    input: {},
    errorHandling: { header: 'Fetching active FMC year' },
  });
  const [suggestedProductId, setSuggestedProductId] = useState<number>();
  const { data: hiddenColumns, queryFunction: refreshHiddenColumns } = useEndpoint({
    endpoint: HiddenColumnsGetEndpoint,
    input: {},
    errorHandling: { header: 'Fetching table columns configurations' },
  });
  const { data: userData, queryFunction: refreshUserSettings } = useEndpoint({
    endpoint: UserMeEndpoint,
    input: {},
    errorHandling: { header: 'Fetching user settings' },
  });
  const { data: emailToName } = useEndpoint({
    endpoint: EmailNameMapEndpoint,
    input: {},
    errorHandling: { header: 'Fetching email to name map' },
  });
  const [isRefreshingUserData, setIsRefreshingUserData] = useState(false);

  const { addSuggestion, suggestions } = useSuggestions();

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (window.location.protocol === 'http:' && !isDev()) {
      alert(
        'It seems you are running in a local environment but the backend/API used is not the development instance. Now throwing error',
      );
      throw new Error();
    }
    document.title = 'Novelty Costing' + (STAGE === 'production' ? '' : ` (${STAGE})`);
  }, []);

  const goToLastVisited = useCallback(() => {
    localforage.getItem(LF_LAST_VISITED).then((v) => {
      if (v && typeof v === 'string') {
        if (isPathAccesible(v, userData?.virtualGroups)) {
          navigate(v);
        } else {
          navigate('/');
        }
      }
    });
  }, [userData, navigate]);

  useEffect(() => {
    fetchDomainUsername();
  }, []);

  useEffect(() => {
    if (!isPathAccesible(location.pathname, userData?.virtualGroups)) {
      navigate('/');
      return;
    }
    if (location.pathname === '/') {
      goToLastVisited();
    }
  }, [userData, navigate, goToLastVisited, location.pathname]);

  async function fetchDomainUsername() {
    const response = await fetch(
      'https://graph.microsoft.com/v1.0/me?$select=onPremisesSamAccountName',
      {
        headers: { Authorization: await getMsGraphToken() },
      },
    );
    if (!response.ok) {
      return;
    }

    const body = await response.json();
    setDomainUsername(body.onPremisesSamAccountName || '');
  }

  const clearProductIndex = useCallback(() => {
    const currentParams = Object.fromEntries(searchParams);
    delete currentParams.productId;
    delete currentParams.revision;
    setSearchParams(currentParams);
  }, [searchParams, setSearchParams]);

  const setProductIndex = useCallback(
    (productId: number, revision: 'new' | number | undefined) => {
      setSearchParams((currParams) => {
        const currentParams = Object.fromEntries(currParams);

        currentParams.productId = `${productId}`;
        if (revision) {
          currentParams.revision = `${revision}`;
        } else {
          delete currentParams.revision;
        }

        return currentParams;
      });
    },
    [setSearchParams],
  );

  const setProductId = useCallback(
    (productId: number) => {
      setSearchParams((currParams) => {
        const currentParams = Object.fromEntries(currParams);

        currentParams.productId = `${productId}`;
        delete currentParams.revision;

        return currentParams;
      });
    },
    [setSearchParams],
  );

  const setRevision = useCallback(
    (revision: 'new' | number | undefined) => {
      setSearchParams((currParams) => {
        const currentParams = Object.fromEntries(currParams);

        if (revision) {
          currentParams.revision = `${revision}`;
        } else {
          delete currentParams.revision;
        }

        return currentParams;
      });
    },
    [setSearchParams],
  );

  useEffect(() => {
    const productId = searchParams.get('productId');

    if (REGEX_NONNEGATIVE_INTEGER.test(productId || '')) {
      const revision = searchParams.get('revision');
      setProductId(Number(productId));
      setRevision(
        revision === 'new'
          ? 'new'
          : REGEX_NONNEGATIVE_INTEGER.test(revision || '')
          ? Number(revision)
          : undefined,
      );
    } else {
      clearProductIndex();
    }
  }, [searchParams, clearProductIndex, setProductId, setRevision]);

  useEffect(() => {
    if (!configData) {
      return;
    }

    primaryDeveloperContact = JSON.parse(configData.developer_contacts)[0] || '';
  }, [configData]);

  function getHiddenColumnIdsForId(tableId: string) {
    return (hiddenColumns || []).filter((c) => c.table_id === tableId).map((c) => c.column_id);
  }

  async function refreshUserData() {
    setIsRefreshingUserData(true);
    await refreshUserSettings();
    setIsRefreshingUserData(false);
  }

  const getProductIndex: () => {
    productId: number | undefined;
    revision: 'new' | number | undefined;
  } = useCallback(() => {
    const currentParams = Object.fromEntries(searchParams);
    return {
      productId: currentParams.productId ? Number(currentParams.productId) : undefined,
      revision:
        currentParams.revision === 'new'
          ? 'new'
          : currentParams.revision
          ? Number(currentParams.revision)
          : undefined,
    };
  }, [searchParams]);

  return (
    <ErrorBoundary
      fallbackRender={({ resetErrorBoundary, error }) => (
        <ErrorBoundaryContent onReset={resetErrorBoundary} error={error} />
      )}
      onReset={() => {
        clearProductIndex();
        goToLastVisited();
      }}
    >
      <UserDataContext.Provider
        value={{
          virtualGroups: userData?.virtualGroups || [],
          showReport: !!(
            userData?.virtualGroups.includes(VirtualGroupName.TheCostingGroup) ||
            userData?.virtualGroups.includes(VirtualGroupName.Administrators)
          ),
          userData: userData || undefined,
          domainUsername,
          email: userData?.email || '',
          refresh: refreshUserData,
          isRefreshing: isRefreshingUserData,
          getName: (email: string | undefined) =>
            !email ? '' : emailToName?.map[email] ?? prettifyUsername(email),
        }}
      >
        <ModalContext.Provider value={setActiveModal}>
          {activeModal && (
            <NCMessageModal
              variant={activeModal.variant}
              header={activeModal.header}
              body={activeModal.body}
              onClose={() => setActiveModal(undefined)}
            />
          )}
          <ConfigContext.Provider
            value={{
              refresh: refreshConfig,
              loading: configLoading,
              config: configData || {
                developer_contacts: JSON.stringify([]),
                fmc_active_year: 0,
                alternative_bom_no_max_inclusive: 0,
                bom_status_min_inclusive: 0,
                bom_status_max_inclusive: 0,
                block_iserver_bom: false,
                block_iserver_item: false,
                vo_period_start: now(),
                vo_period_end: now(),
              },
            }}
          >
            <ProductIndexContext.Provider
              value={{
                setProductIndex,
                setProductId,
                setRevision,
                getProductIndex,
                clearProductIndex,
                suggestedProductId,
                setSuggestedProductId,
                addSuggestion,
                suggestions,
              }}
            >
              <LoadingOverlayContext.Provider
                value={{ isLoading: loadingOverlayOn, setIsLoading: setLoadingOverlayOn }}
              >
                <HiddenColumnsContext.Provider
                  value={{ getHiddenColumnIdsForId, refreshHiddenColumns }}
                >
                  <div style={{ width: '100vw', height: '100vh' }}>
                    <NavigationBar />
                    <div
                      style={{
                        display: 'grid',
                        gridTemplateColumns:
                          'minmax(20px, 1fr) minmax(600px, 1200px) minmax(20px, 1fr)',
                        height: 'calc(100% - 72px)',
                        overflow: 'auto',
                      }}
                    >
                      <div />
                      <LoadingOverlay loading={loadingOverlayOn} />
                      <div style={{ padding: '24px 0 128px 0' }}>
                        <Routes>
                          <Route path="/" element={<WelcomePage />} />
                          <Route path={allRoutes.Elements} element={<ElementsPage />} />
                          <Route path={pageRoutes['Packaging']} element={<PackagingPage />} />
                          <Route path={pageRoutes['Instructions']} element={<BIPage />} />
                          <Route path={pageRoutes['Final costs']} element={<FinalCostsPage />} />
                          <Route path={allRoutes.Administration} element={<AdministrationPage />} />
                          <Route
                            path={allRoutes.Reporting['Elements costs']}
                            element={
                              <CommonReportPage
                                type="Elements costs"
                                endpoint={ReportElementsCostsGetEndpoint}
                              />
                            }
                          />
                          <Route
                            path={allRoutes.Reporting['Elements errors']}
                            element={
                              <CommonReportPage
                                type="Elements errors"
                                endpoint={ReportElementsErrorsGetEndpoint}
                              />
                            }
                          />
                          <Route
                            path={allRoutes.Reporting.Packaging}
                            element={
                              <CommonReportPage
                                type="Packaging"
                                endpoint={ReportPackagingGetEndpoint}
                              />
                            }
                          />
                          <Route
                            path={allRoutes.Reporting.Instructions}
                            element={
                              <CommonReportPage
                                type="Building instructions"
                                endpoint={ReportBIGetEndpoint}
                              />
                            }
                          />
                          <Route
                            path={allRoutes.Reporting['Instructions (LBX)']}
                            element={
                              <CommonReportPage
                                type="Building instructions (LBX)"
                                endpoint={ReportBILBXGetEndpoint}
                              />
                            }
                          />
                          <Route
                            path={allRoutes.Reporting['Costing status']}
                            element={<CostingStatusReportPage />}
                          />
                          <Route
                            path={allRoutes.Reporting['Final costs']}
                            element={
                              <CommonReportPage
                                type="Final costs"
                                endpoint={ReportFinalCostsGetEndpoint}
                              />
                            }
                          />
                          <Route path={allRoutes.Settings} element={<SettingsPage />} />
                          <Route path="*" element={<NotFoundPage />} />
                        </Routes>
                      </div>
                    </div>
                  </div>
                </HiddenColumnsContext.Provider>
              </LoadingOverlayContext.Provider>
            </ProductIndexContext.Provider>
          </ConfigContext.Provider>
        </ModalContext.Provider>
      </UserDataContext.Provider>
    </ErrorBoundary>
  );
}

export default App;
