import { extendZodWithOpenApi } from '@asteasolutions/zod-to-openapi';
import { z } from 'zod';

let hasRunOnce = false;

(() => {
  if (!hasRunOnce) {
    hasRunOnce = true;
    extendZodWithOpenApi(z);
  }
})();
