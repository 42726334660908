import { PK_TO_PRETTY_NAME } from '@core/const/const.PK_TO_PRETTY_NAME';
import { AdministrationTransferDataEndpoint } from '@core/schemas/endpoint/schema.endpoint.administration';
import { DataMaintenanceGetDataAdminConfigEndpoint } from '@core/schemas/endpoint/schema.endpoint.dataMaintenance';
import { PK } from '@core/types/types.pk';
import { unique } from '@core/util/util.unique';
import { callEndpoint } from '@frontend/common/lib/callEndpoint';
import { showInternalApplicationError, showSuccessToast } from '@frontend/common/lib/functions';
import { useEndpoint } from '@frontend/common/lib/hooks/useEndpoint';
import { FCheckbox } from '@frontend/form/components/FCheckbox';
import { FSelect } from '@frontend/form/components/FSelect';
import { Button } from '@lego/klik-ui';
import React, { useMemo, useState } from 'react';

export function MigrateData() {
  const [selectedPks, setSelectedPks] = useState<PK[]>([]);
  const [selectedSource, setSelectedSource] = useState<'development' | 'test' | 'production' | ''>(
    '',
  );

  const { data: permissions, loading: fetchingPermissions } = useEndpoint({
    endpoint: DataMaintenanceGetDataAdminConfigEndpoint,
    input: null,
    errorHandling: { header: 'Fetching data admin permissions' },
  });
  const [isLoading, setIsLoading] = useState(false);

  async function triggerMigrate() {
    if (selectedPks.length === 0 || !selectedSource) {
      showInternalApplicationError('Trigger migration');
      return;
    }

    setIsLoading(true);
    const [err] = await callEndpoint({
      endpoint: AdministrationTransferDataEndpoint,
      input: { from: selectedSource, pks: selectedPks },
      errorHandling: { header: 'Migrate data' },
    });
    setIsLoading(false);

    if (err) {
      return;
    }

    showSuccessToast('Migrate data', 'Request for data migration successfully sent');

    setSelectedPks([]);
  }

  function handlePkClick(pk: PK) {
    setSelectedPks((curr) => {
      if (curr.includes(pk)) {
        return curr.filter((v) => v !== pk);
      }
      return unique(curr.concat(pk));
    });
  }

  const options = useMemo(
    () =>
      !permissions
        ? []
        : [
            ...Object.entries(PK_TO_PRETTY_NAME)
              .filter(([pk]) => permissions[pk as PK]?.write)
              .map(([pk, description]) => ({
                value: pk,
                text: description,
              }))
              .sort(({ text: textA }, { text: textB }) => textA.localeCompare(textB)),
          ],
    [permissions],
  );

  return (
    <div>
      <div>
        Migration of data can take up to 15 minutes. A mail will be sent to you once the migration
        has been completed.
      </div>
      <div>
        <FSelect
          placeholder={'Select source environment'}
          options={[
            { text: 'Development', value: 'development', disabled: STAGE === 'development' },
            { text: 'Test', value: 'test', disabled: STAGE === 'test' },
            { text: 'Production', value: 'production', disabled: STAGE === 'production' },
          ]}
          labelStyle={{ fontSize: 18, fontWeight: 'bold' }}
          editable
          value={selectedSource}
          onChange={(v) => setSelectedSource(v as typeof selectedSource)}
          style={{ width: 500, marginTop: 24 }}
        />
        {fetchingPermissions ? (
          <div style={{ margin: '16px 0' }}>Loading...</div>
        ) : (
          <>
            {' '}
            <Button
              size="sm"
              variant="outline"
              style={{ marginTop: 24 }}
              onClick={() => {
                if (selectedPks.length === options.length) {
                  options.forEach((option) => handlePkClick(option.value as PK));
                } else {
                  options
                    .filter((option) => !selectedPks.includes(option.value as PK))
                    .forEach((option) => handlePkClick(option.value as PK));
                }
              }}
            >
              {selectedPks.length === options.length ? 'Deselect all' : 'Select all'}
            </Button>
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: 'min-content auto',
                alignItems: 'center',
                columnGap: 16,
                marginTop: 12,
              }}
            >
              {options.map((option) => (
                <React.Fragment key={option.value}>
                  <FCheckbox
                    editable
                    checked={selectedPks.includes(option.value as PK)}
                    onChange={() => handlePkClick(option.value as PK)}
                  />
                  <div
                    style={{ cursor: 'pointer' }}
                    onClick={() => handlePkClick(option.value as PK)}
                  >
                    {option.text}
                  </div>
                </React.Fragment>
              ))}
            </div>
          </>
        )}
        <Button
          style={{ marginTop: 16 }}
          size="sm"
          disabled={selectedPks.length === 0 || !selectedSource}
          isLoading={isLoading}
          onClick={triggerMigrate}
        >
          Migrate
        </Button>
      </div>
    </div>
  );
}
