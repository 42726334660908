import { DBProdHeadBI } from '@core/schemas/db/schema.db.bi';
import { DBProdHeadElem } from '@core/schemas/db/schema.db.elements';
import { DBProdHeadPack } from '@core/schemas/db/schema.db.packaging';

export function checkApproveFinalCost(input: {
  overheadType: number | undefined;
  scrapType: number | undefined;
  prodHeadElem: DBProdHeadElem | undefined | null;
  prodHeadPack: DBProdHeadPack | undefined | null;
  prodHeadBi: DBProdHeadBI | undefined | null;
  fmcYear: number;
}) {
  const { overheadType, scrapType, prodHeadBi, prodHeadElem, prodHeadPack, fmcYear } = input;

  if (!overheadType) {
    throw new Error('Overhead type must be specified');
  }

  if (!scrapType) {
    throw new Error('Scrap type must be specified');
  }

  if (prodHeadElem && (!prodHeadElem.approved_by || prodHeadElem.year !== fmcYear)) {
    throw new Error('Elements revision must be approved in current FMC year');
  }

  if (prodHeadPack && (!prodHeadPack.approved_by || prodHeadPack.year !== fmcYear)) {
    throw new Error('Packaging revision must be approved in current FMC year');
  }

  if (prodHeadBi && (!prodHeadBi.approved_by || prodHeadBi.year !== fmcYear)) {
    throw new Error('BI revision must be approved in current FMC year');
  }
}
