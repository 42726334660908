import { TableColumn } from '@frontend/table/table';
import { Button } from '@lego/klik-ui-button';
import { FilesXlsBold } from '@lego/klik-ui-icons';
import { CSSProperties } from 'react';
import writeXlsxFile from 'write-excel-file';
import { convertToXlsxDataFormat } from '@core/util/util.convertToXlsxFormat';

interface ExportButtonProps<T extends object, S extends keyof T> {
  rows: T[];
  style?: CSSProperties;
  columns: TableColumn<T>[];
  children?: string;
  mappers?: [S, (v: T[S]) => string | boolean | number][];
}

export function ExportButton<T extends object, S extends keyof T>(props: ExportButtonProps<T, S>) {
  async function onClick(rows: T[]) {
    await writeXlsxFile(
      convertToXlsxDataFormat(
        rows,
        props.columns.filter((c) => !c.ignoreInExport),
        props.mappers,
      ),
      {
        fileName: 'file.xlsx',
      },
    );
  }

  return (
    <Button
      onClick={() => onClick(props.rows)}
      leftIcon={<FilesXlsBold />}
      size="sm"
      style={props.style}
    >
      {props.children || 'Export'}
    </Button>
  );
}
