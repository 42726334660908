import { useContext, useEffect, useMemo, useState } from 'react';
import { Button, Link, useDisclosure } from '@lego/klik-ui';
import {
  showErrorToast,
  showInternalApplicationError,
  showSuccessToast,
  showWarningToast,
} from '../../../common/lib/functions';
import { useLocation } from 'react-router-dom';
import { NCModal } from '../../../common/components/NCModal';
import { ProductIdSelectionsContent } from '../../../reporting/components/ProductIdSelectionsContent';
import { FInput } from '../../../form/components/FInput';
import { REGEX_NONNEGATIVE_INTEGER_OR_EMPTY } from '@core/types/types.regex';
import { CrossBold } from '@lego/klik-ui/icons';
import { Tag } from '../../../common/components/Tag';
import { FSelect } from '../../../form/components/FSelect';
import { callEndpoint } from '../../../common/lib/callEndpoint';
import {
  AdministrationYearChangeStageEndpoint,
  AdministrationYearChangeUpdateEndpoint,
} from '@core/schemas/endpoint/schema.endpoint.administration';
import { convertProductIdTo8digits } from '@core/util/util.convertProductIdTo8digits';
import { ConfigContext, UserDataContext } from '@frontend/common/lib/contexts';
import { FCheckbox } from '@frontend/form/components/FCheckbox';
import { ViewName } from '@core/schemas/schema.common';
import { unique } from '@core/util/util.unique';
import { MessageStack } from '@frontend/common/components/MessageStack';
import { YearChangeError } from '@core/schemas/types/schemas.types';
import { prettyViewName } from '@core/util/util.prettyViewName';
import { ExportButton } from '@frontend/common/components/ExportButton';

interface YearChangeActionsProps {
  years: number[];
  refreshOverview(): void;
}

export function YearChangeActions(props: YearChangeActionsProps) {
  const [productId, setProductId] = useState('');
  const [productIds, setProductIds] = useState<number[]>([]);
  const [productIdRangeFrom, setProductIdRangeFrom] = useState('');
  const [productIdRangeTo, setProductIdRangeTo] = useState('');
  const [productIdRanges, setProductIdRanges] = useState<{ from: number; to: number }[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedAction, setSelectedAction] = useState<'update' | 'approve' | 'stage' | 'remove'>();
  const [selectedScreens, setSelectedScreens] = useState<ViewName[]>([]);
  const [errors, setErrors] = useState<YearChangeError[]>([]);

  const { isOpen, onClose, onOpen } = useDisclosure();
  const location = useLocation();
  const { config } = useContext(ConfigContext);
  const { domainUsername } = useContext(UserDataContext);

  useEffect(() => {
    setProductId('');
    setProductIds([]);
    setProductIdRangeFrom('');
    setProductIdRangeTo('');
    setProductIdRanges([]);
  }, [location.pathname]);

  function addProductId(input: string) {
    setProductIds((curr) => curr.filter((pid) => pid !== Number(input)).concat(Number(input)));
  }

  function addCurrentProductId() {
    addProductId(productId);
    setProductId('');
  }

  function addProductIdRange() {
    if (!productIdRangeFrom || !productIdRangeTo) {
      return;
    }

    const from = Number(productIdRangeFrom);
    const to = Number(productIdRangeTo);

    const newRange = { from: from <= to ? from : to, to: from <= to ? to : from };

    setProductIdRanges((ranges) =>
      ranges.filter((range) => JSON.stringify(range) !== JSON.stringify(newRange)).concat(newRange),
    );
    setProductIdRangeFrom('');
    setProductIdRangeTo('');
  }

  function removeProductId(productId: number) {
    setProductIds((curr) => curr.filter((pid) => pid !== productId));
  }

  function removeProductIdRange(productIdRange: { from: number; to: number }) {
    setProductIdRanges((curr) =>
      curr.filter((range) => JSON.stringify(range) !== JSON.stringify(productIdRange)),
    );
  }

  const selections = useMemo(
    () => [...productIds, ...productIdRanges],
    [productIds, productIdRanges],
  );

  async function onOk() {
    setErrors([]);

    if (!selectedAction) {
      showInternalApplicationError('Action or year not selected when clicking OK');
      return;
    }

    if (productId || productIdRangeFrom || productIdRangeTo) {
      showErrorToast(
        'Processing FMC year change',
        'Product ID or product ID range has been filled but not added. Either add or remove the entry/entries',
      );
      return;
    }

    if (selectedAction === 'stage' || selectedAction === 'remove') {
      setIsLoading(true);
      const [err] = await callEndpoint({
        endpoint: AdministrationYearChangeStageEndpoint,
        input: {
          productIds: productIds.map(convertProductIdTo8digits),
          remove: selectedAction === 'remove',
        },
        errorHandling: { header: 'Processing FMC year change' },
      });
      setIsLoading(false);

      if (err) {
        return;
      }

      showSuccessToast(
        'FMC year change',
        `Successfully ${selectedAction === 'stage' ? 'staged' : 'unstaged'} ${
          productIds.length
        } product${productIds.length === 1 ? '' : 's'} ${
          selectedAction === 'stage' ? 'for' : 'from'
        } year change`,
      );
    } else {
      setIsLoading(true);
      const [err, data] = await callEndpoint({
        endpoint: AdministrationYearChangeUpdateEndpoint,
        input: {
          productIdRanges: productIdRanges.map((pidr) => ({
            ...pidr,
            from: convertProductIdTo8digits(pidr.from),
            to: convertProductIdTo8digits(pidr.to),
          })),
          productIds: productIds.map(convertProductIdTo8digits),
          screens: selectedScreens,
          action: selectedAction,
          domainUsername,
        },
        errorHandling: { header: 'Processing FMC year change' },
      });
      setIsLoading(false);

      setErrors(data?.errors || []);

      if (err) {
        return;
      }

      if (data.errors.length === 0) {
        showSuccessToast(
          'FMC year change',
          `Successfully processed FMC year change for screen ${selectedAction}`,
        );
      } else {
        showWarningToast(
          'FMC year change',
          `Successfully processed FMC year change for screen ${selectedAction} with ${
            data.errors.length
          } error${data.errors.length === 1 ? '' : 's'}`,
        );
      }
    }

    props.refreshOverview();
    setSelectedScreens([]);
    setSelectedAction(undefined);
  }

  function udpateSelectedScreen(view: ViewName) {
    setSelectedScreens((curr) => {
      if (curr.includes(view)) {
        return curr.filter((v) => v !== view);
      }

      return unique(curr.concat(view));
    });
  }

  const fmcYearUpdated = useMemo(
    () => config.fmc_active_year !== new Date().getFullYear(),
    [config.fmc_active_year],
  );

  return (
    <div style={{ display: 'flex', columnGap: 60 }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: 300,
          rowGap: 24,
          columnGap: 16,
          alignItems: 'center',
        }}
      >
        <div style={{ width: '100%' }}>
          <div style={{ display: 'flex', columnGap: 8, alignItems: 'end', width: '100%' }}>
            <FInput
              topLabel="Product ID"
              editable
              align="start"
              regex={REGEX_NONNEGATIVE_INTEGER_OR_EMPTY}
              disallowChangeOnInvalid
              onMultiValuePaste={(productIds) => {
                productIds.map(addProductId);
                setTimeout(() => setProductId(''), 0);
              }}
              disableSmartBehavior
              value={productId}
              disableBlurOnEnter
              onChange={setProductId}
              onEnter={addCurrentProductId}
              style={{ width: 'inherit' }}
            />
            <Button
              colorScheme="success"
              disabled={!productId}
              onClick={addCurrentProductId}
              size="sm"
            >
              Add
            </Button>
          </div>
          <div>
            <div style={{ marginTop: 8, marginBottom: 4 }}>Product ID range</div>
            <div
              style={{
                display: 'flex',
                columnGap: 8,
                alignItems: 'end',
                width: '100%',
              }}
            >
              <FInput
                placeholder="From"
                editable
                align="start"
                regex={REGEX_NONNEGATIVE_INTEGER_OR_EMPTY}
                disallowChangeOnInvalid
                disableSmartBehavior
                value={productIdRangeFrom}
                disableBlurOnEnter
                onChange={setProductIdRangeFrom}
                onEnter={addProductIdRange}
                style={{ width: 'inherit' }}
              />
              <div style={{ marginBottom: 6 }}>-</div>
              <FInput
                editable
                placeholder="To"
                align="start"
                regex={REGEX_NONNEGATIVE_INTEGER_OR_EMPTY}
                disallowChangeOnInvalid
                disableSmartBehavior
                value={productIdRangeTo}
                disableBlurOnEnter
                onChange={setProductIdRangeTo}
                onEnter={addProductIdRange}
                style={{ width: 'inherit' }}
              />
              <Button
                colorScheme="success"
                size="sm"
                disabled={!productIdRangeFrom || !productIdRangeTo}
                onClick={addProductIdRange}
              >
                Add
              </Button>
            </div>
          </div>
          {selections.length > 0 && (
            <div
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                columnGap: 4,
                rowGap: 4,
                marginTop: 16,
              }}
            >
              {selections.slice(0, 5).map((idOrRange) => (
                <Tag
                  key={
                    typeof idOrRange === 'number' ? idOrRange : idOrRange.from + ',' + idOrRange.to
                  }
                  style={{
                    fontSize: 14,
                    height: 28,
                    padding: '0 8px',
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    if (typeof idOrRange === 'number') {
                      removeProductId(idOrRange);
                    } else {
                      removeProductIdRange(idOrRange);
                    }
                  }}
                >
                  {typeof idOrRange === 'number' ? idOrRange : idOrRange.from + '-' + idOrRange.to}
                  <CrossBold style={{ fontSize: 10, marginLeft: 4, marginTop: -2 }} />
                </Tag>
              ))}
            </div>
          )}
          {selections.length > 5 && (
            <div style={{ marginTop: 8, textAlign: 'center' }}>
              And {selections.length - 5} more.
              <Link onClick={onOpen} style={{ marginLeft: 8 }} className="link-message">
                Show
              </Link>
            </div>
          )}
        </div>
        <div style={{ width: '100%' }}>
          <div style={{ width: '100%', display: 'flex', alignItems: 'start' }}>
            <FSelect
              topLabel="Action"
              placeholder="Select action for products..."
              containerStyle={{ width: '100%' }}
              value={selectedAction}
              onChange={(v) => setSelectedAction(v as 'update' | 'approve' | 'stage' | 'remove')}
              editable
              options={[
                { text: 'Stage', value: 'stage' },
                { text: 'Unstage', value: 'remove' },
                {
                  text: `Update revisions${!fmcYearUpdated ? ` (Update FMC year first)` : ''}`,
                  value: 'update',
                  disabled: !fmcYearUpdated,
                },
                {
                  text: `Approve revisions${!fmcYearUpdated ? ` (Update FMC year first)` : ''}`,
                  value: 'approve',
                  disabled: !fmcYearUpdated,
                },
              ]}
              labelStyle={{ fontWeight: 'normal' }}
            />
          </div>
          {(selectedAction === 'approve' || selectedAction === 'update') && (
            <div
              style={{
                display: 'grid',
                rowGap: 4,
                columnGap: 12,
                gridTemplateColumns: 'min-content auto',
                alignItems: 'center',
                alignSelf: 'start',
                marginTop: 12,
              }}
            >
              <FCheckbox
                editable
                checked={selectedScreens.includes('elements')}
                onChange={() => udpateSelectedScreen('elements')}
              />
              <div style={{ cursor: 'pointer' }} onClick={() => udpateSelectedScreen('elements')}>
                Elements screen
              </div>
              <FCheckbox
                editable
                checked={selectedScreens.includes('packaging')}
                onChange={() => udpateSelectedScreen('packaging')}
              />
              <div style={{ cursor: 'pointer' }} onClick={() => udpateSelectedScreen('packaging')}>
                Packaging screen
              </div>
              <FCheckbox
                editable
                checked={selectedScreens.includes('bi')}
                onChange={() => udpateSelectedScreen('bi')}
              />
              <div style={{ cursor: 'pointer' }} onClick={() => udpateSelectedScreen('bi')}>
                Instructions screen
              </div>
              <FCheckbox
                editable
                checked={selectedScreens.includes('finalcosts')}
                onChange={() => udpateSelectedScreen('finalcosts')}
              />
              <div style={{ cursor: 'pointer' }} onClick={() => udpateSelectedScreen('finalcosts')}>
                Final costs screen
              </div>
            </div>
          )}
        </div>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            size="sm"
            disabled={
              (productIds.length === 0 && productIdRanges.length === 0) ||
              !selectedAction ||
              ((selectedAction === 'update' || selectedAction === 'approve') &&
                selectedScreens.length === 0)
            }
            isLoading={isLoading}
            onClick={onOk}
          >
            OK
          </Button>
        </div>
      </div>
      {
        <div>
          {errors.length > 0 && (
            <ExportButton
              rows={errors}
              columns={[
                { title: 'Product ID', dataIndex: 'productId' },
                {
                  title: 'Screen',
                  dataIndex: 'screen',
                  format: (v: ViewName) => prettyViewName(v),
                },
                { title: 'Message', dataIndex: 'message' },
              ]}
              style={{ marginBottom: 16 }}
            >
              Export errors
            </ExportButton>
          )}
          <MessageStack
            messages={errors.map((error, i) => ({
              id: `${i}`,
              message: `${error.productId}, ${prettyViewName(error.screen)}: ${error.message}`,
              variant: 'error',
            }))}
          />
        </div>
      }
      <NCModal header="Product ID selections" isOpen={isOpen} onClose={onClose} width={600}>
        <ProductIdSelectionsContent
          productIds={productIds}
          productIdRanges={productIdRanges}
          onRemove={(selection) => {
            if (selection.type === 'single') {
              removeProductId(Number(selection.value));
            } else {
              const [from, to] = selection.value.split('-').map((v) => Number(v));
              removeProductIdRange({ from, to });
            }
          }}
          onMultiValuePaste={(productIds) => {
            productIds.map(addProductId);
            setTimeout(() => setProductId(''), 1);
          }}
          addCurrentProductId={addProductId}
        />
      </NCModal>
    </div>
  );
}
