import { z } from 'zod';
import { YearChangeStatus } from '../db/schema.db.yearChange';
import { ViewNameEnum } from '../schema.common';

export type YearChangeOverviewRecord = z.infer<typeof YearChangeOverviewRecordSchema>;
export const YearChangeOverviewRecordSchema = z.object({
  productId: z.number().int(),
  elements: z.object({
    revision: z.number().int().optional(),
    status: z.nativeEnum(YearChangeStatus),
    message: z.string(),
  }),
  bi: z.object({
    revision: z.number().int().optional(),
    status: z.nativeEnum(YearChangeStatus),
    message: z.string(),
  }),
  packaging: z.object({
    revision: z.number().int().optional(),
    status: z.nativeEnum(YearChangeStatus),
    message: z.string(),
  }),
  finalcosts: z.object({
    revision: z.number().int().optional(),
    status: z.nativeEnum(YearChangeStatus),
    message: z.string(),
  }),
});

export type YearChangeError = z.infer<typeof YearChangeErrorSchema>;
export const YearChangeErrorSchema = z.object({
  productId: z.number().int(),
  screen: ViewNameEnum,
  message: z.string(),
});
