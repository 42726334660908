import { Region } from '@core/schemas/schema.common';
import {
  CalculatePackagingNonstandardCostMaterials,
  CalculatePackagingNonstandardCostReturnValue,
  CalculatePackagingNonstandardCostRow,
} from './calculate.PackagingCosts';
import { DBPackagingScrapRate } from '@core/schemas/db/schema.db.packaging';
import { ElementPrice } from '@core/types/types.elements';
import { Calculate_FetchQuery } from '../calculator.types';
import {
  Calculator,
  CalculationError,
  InternalCalculatorError,
  useCacheOrFetchQuery,
} from '../calculator';
import {
  ReportStatement,
  getCostCustomReportStatement,
  getCostReportStatement,
  getRateReportStatement,
  getReportStatement,
} from '../calculator.util.report';
import { PK } from '@core/types/types.pk';
import { PackSource, PackagingCostMaterial } from '@core/types/types.packaging';
import { round2 } from '../calculator.util';
import { MATERIAL_NOT_FOUND_TEXT } from '@core/const/const.MATERIAL_NOT_FOUND_TEXT';

export async function calculate_packagingNonstandardCost(props: {
  input: {
    packagingNonstandardCost: CalculatePackagingNonstandardCostRow;
    packingRegionEU: Region;
    packingRegionUS: Region;
    source: PackSource;
    fmcYear: number;
  };
  cache?: {
    materials?: CalculatePackagingNonstandardCostMaterials;
    packagingScrapRates?: DBPackagingScrapRate[];
    cost?: {
      fmc1_cost?: number;
      fmc2_cost?: number;
      fmc1_cost_plus_scrap?: number;
      fmc2_cost_plus_scrap?: number;
    };
  };
  query?: {
    getElementPricing?: (materialId: number) => Promise<ElementPrice | undefined>;
    query?: Calculate_FetchQuery;
  };
}): Promise<CalculatePackagingNonstandardCostReturnValue> {
  const { input, cache, query } = props;

  if (cache?.cost) {
    return handleCostCache(cache.cost);
  }

  const packagingScrapRates = await useCacheOrFetchQuery({
    cachedItems: cache?.packagingScrapRates,
    fetchQuery: query?.query,
    fetchQueryArgs: {
      pk: PK.PackagingScrapRate,
      query: Calculator.Pack.Packaging.NonstandardCost.Filter.PackagingScrapRates({
        year: input.fmcYear,
        source: input.source,
      }),
    },
    dataTypeDescription: 'scrap rates',
  });

  async function calculateRegional(props: {
    meta: {
      region: Region;
      source: PackSource;
    };
    input: {
      year: number;
      materialId?: number;
      manualCost?: number;
      quantity: number;
      packingRegion: Region;
    };
    cache?: { material?: PackagingCostMaterial };
  }): Promise<{
    cost: number;
    cost_plus_scrap: number;
    report: ReportStatement[];
  }> {
    const { input, meta, cache } = props;

    const report: ReportStatement[] = [];

    const packagingScrapRate = packagingScrapRates.find(
      (r) => r.packing_region === input.packingRegion,
    );

    if (!packagingScrapRate) {
      throw new CalculationError({
        report: getRateReportStatement({
          region: meta.region,
          rateType: 'Scrap rate',
          rate: 'Not found',
          rateIdentifier: [
            ,
            [{ Year: input.year, Source: meta.source, 'Packing region': input.packingRegion }],
          ],
        }),
      });
    }

    const scrapRate = packagingScrapRate.rate_percent;

    report.push(
      getRateReportStatement({
        region: meta.region,
        rateType: 'Scrap rate',
        rate: scrapRate,
        rateIdentifier: [packagingScrapRate, ['year', 'source', 'packing_region']],
        unit: '%',
      }),
    );

    let materialCost: undefined | number;

    if (cache?.material) {
      if (cache.material.description === MATERIAL_NOT_FOUND_TEXT) {
        throw new CalculationError({
          region: meta.region,
          message: `Material ${cache.material.materialId} does not exist`,
        });
      }
      materialCost = cache.material.cost;
    } else if (input.materialId) {
      if (!query?.getElementPricing) {
        throw new InternalCalculatorError(
          InternalCalculatorError.getErrorMessageMissingQueryFunction('material cost'),
        );
      }
      const material = await fetchMaterialCost(input.materialId, query.getElementPricing);
      if (material) {
        report.push(
          getRateReportStatement({
            region: meta.region,
            rate: materialCost,
            rateType: 'Material',
            rateIdentifier: [material, ['materialId']],
          }),
        );

        const { rate, report: selectPriceReport } = Calculator.Elements.SelectPrice(
          material,
          input.year,
        );
        report.push(...selectPriceReport);

        materialCost = rate;

        report.push(
          getReportStatement({
            region: meta.region,
            description: `Using ${input.packingRegion} (Packing region) cost`,
            value: materialCost,
          }),
        );
      } else {
        throw new CalculationError({
          region: meta.region,
          message: `Material ${input.materialId} does not exist`,
        });
      }
    }
    let cost: number | undefined = undefined;

    if (materialCost === undefined) {
      if (props.input.manualCost === undefined) {
        throw new CalculationError({
          region: meta.region,
          message: 'Manual cost used but was not provided',
        });
      }
      cost = props.input.manualCost;

      report.push(
        getCostReportStatement({
          region: meta.region,
          cost,
          rate: ['Manual cost', props.input.manualCost],
        }),
      );
    } else {
      cost = input.quantity * materialCost;
      report.push(
        getCostReportStatement({
          region: meta.region,
          cost,
          quantity: input.quantity,
          rate: ['Material cost', materialCost],
        }),
      );
    }

    const costPlusScrap = cost * (1 + Calculator.Common.QuotientStrict(scrapRate, 100));
    report.push(
      getCostCustomReportStatement({
        region: meta.region,
        cost: costPlusScrap,
        description: `Cost (${cost}) + Scrap rate (${scrapRate}%)`,
        extraTag: 'plus scrap',
      }),
    );

    return {
      cost,
      cost_plus_scrap: costPlusScrap,
      report,
    };
  }

  const [
    { cost: fmc1_cost, cost_plus_scrap: fmc1_cost_plus_scrap, report: fmc1_report },
    { cost: fmc2_cost, cost_plus_scrap: fmc2_cost_plus_scrap, report: fmc2_report },
  ] = await Promise.all([
    calculateRegional({
      meta: { region: 'EU', source: input.source },
      input: {
        year: input.fmcYear,
        manualCost: input.packagingNonstandardCost.fmc1manualCost,
        materialId: input.packagingNonstandardCost.fmc1material,
        quantity: input.packagingNonstandardCost.fmc1quantity,
        packingRegion: input.packingRegionEU,
      },
      cache: { material: cache?.materials?.fmc1 },
    }),
    calculateRegional({
      meta: { region: 'US', source: input.source },
      input: {
        year: input.fmcYear,
        manualCost: input.packagingNonstandardCost.fmc2manualCost,
        materialId: input.packagingNonstandardCost.fmc2material,
        quantity: input.packagingNonstandardCost.fmc2quantity,
        packingRegion: input.packingRegionUS,
      },
      cache: { material: cache?.materials?.fmc2 },
    }),
  ]);

  return {
    fmc1_cost: round2(fmc1_cost),
    fmc2_cost: round2(fmc2_cost),
    fmc1_cost_plus_scrap: round2(fmc1_cost_plus_scrap),
    fmc2_cost_plus_scrap: round2(fmc2_cost_plus_scrap),
    report: [...fmc1_report, ...fmc2_report],
  };
}

async function fetchMaterialCost(
  materialId: number,
  getElementPricing: (materialId: number) => Promise<ElementPrice | undefined>,
): Promise<ElementPrice | undefined> {
  return (await getElementPricing(materialId)) ?? undefined;
}

function handleCostCache(cache: {
  fmc1_cost?: number;
  fmc2_cost?: number;
  fmc1_cost_plus_scrap?: number;
  fmc2_cost_plus_scrap?: number;
}): CalculatePackagingNonstandardCostReturnValue {
  if (cache.fmc1_cost === undefined) {
    throw new CalculationError({
      region: 'EU',
      message: 'Cost was provided as cache but fmc1_cost was missing',
    });
  }

  if (cache.fmc2_cost === undefined) {
    throw new CalculationError({
      region: 'US',
      message: 'Cost was provided as cache but fmc2_cost was missing',
    });
  }

  if (cache.fmc1_cost_plus_scrap === undefined) {
    throw new CalculationError({
      region: 'EU',
      message: 'Cost was provided as cache but fmc1_cost_plus_scrap was missing',
    });
  }

  if (cache.fmc2_cost_plus_scrap === undefined) {
    throw new CalculationError({
      region: 'US',
      message: 'Cost was provided as cache but fmc2_cost_plus_scrap was missing',
    });
  }

  return {
    fmc1_cost: round2(cache.fmc1_cost),
    fmc2_cost: round2(cache.fmc2_cost),
    fmc1_cost_plus_scrap: round2(cache.fmc1_cost_plus_scrap),
    fmc2_cost_plus_scrap: round2(cache.fmc2_cost_plus_scrap),
    report: [
      getCostReportStatement({ region: 'EU', cost: cache.fmc1_cost, cache: true }),
      getCostReportStatement({ region: 'US', cost: cache.fmc2_cost, cache: true }),
      getCostReportStatement({
        region: 'EU',
        cost: cache.fmc1_cost_plus_scrap,
        cache: true,
        extraTag: 'plus scrap',
      }),
      getCostReportStatement({
        region: 'US',
        cost: cache.fmc2_cost_plus_scrap,
        cache: true,
        extraTag: 'plus scrap',
      }),
    ],
  };
}
