import { ExcelConvertionColumn } from '@core/types/types.xlsxConvertion';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function convertToXlsxDataFormat<T extends Record<string, any>, S extends keyof T>(
  rows: T[],
  columns: ExcelConvertionColumn<T>[],
  mappers?: [S, (v: T[S]) => string | boolean | number][],
) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const data: any[] = [
    // Add header
    columns?.map((c) => ({
      value: (c.groupName ? c.groupName + ' - ' : '') + c.title,
      fontWeight: 'bold',
      backgroundColor: '#D9D9D9',
    })),
  ];

  const mappedRows = rows.map((r, i) => {
    const newR = structuredClone(r);
    columns.forEach(({ dataIndex, format }) => {
      if (dataIndex && format) {
        newR[dataIndex] = format(
          typeof dataIndex === 'string' ? getProp(newR, dataIndex) : newR[dataIndex],
          newR,
          i,
          rows,
        ) as T[S];
      }
    });
    mappers?.forEach(([k, m]) => (newR[k] = m(newR[k]) as T[S]));
    return newR;
  });

  mappedRows.forEach((row, i) => {
    data.push(
      columns
        .filter((c) => !c.ignoreInExport)
        .map((c) => {
          // Get value
          let value =
            c.dataIndex !== ''
              ? row[c.dataIndex]
              : c.format
              ? c.format('', row, i, mappedRows)
              : '';
          let type: StringConstructor | NumberConstructor = String;

          if (typeof value === 'number') {
            type = Number;
          } else if (typeof value === 'boolean') {
            value = value ? 'true' : 'false';
          } else if (typeof value === 'undefined' || value === null) {
            // Convert to string
            value = '';
          } else if (typeof value !== 'string') {
            // Throw error
            console.log(c.dataIndex, typeof value, value);
            throw new Error('Convertion error');
          }

          return {
            type,
            value,
          };
        }),
    );
  });

  return data;
}

export function getProp(object: unknown, property: string) {
  if (!object || !property || typeof object !== 'object') {
    return undefined;
  }

  const properties = property.split('.');
  let currentObject = object;
  let index = 0;
  let currentProperty = properties[index];

  while (currentObject.hasOwnProperty(currentProperty)) {
    if (index === properties.length - 1) {
      // @ts-ignore
      return currentObject[currentProperty];
    } else {
      // @ts-ignore
      currentObject = currentObject[currentProperty];
      if (currentObject === undefined) {
        return undefined;
      }
      index++;
      currentProperty = properties[index];
    }
    if (currentObject === undefined) {
      return undefined;
    }
  }

  return undefined;
}
