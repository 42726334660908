import { DBElemCost } from '@core/schemas/db/schema.db.elements';
import { CalculationError, Calculator } from '../calculator';
import { CostComponentFull, CalculatorCommonPPO } from '../calculator.types';
import { round2relaxed } from '../calculator.util';

export function calculate_CC_ElementsScrap_full(props: {
  elements: DBElemCost[];
  ppos: CalculatorCommonPPO;
  scrapRatePercent?: number;
  copyPpoToCost?: boolean;
  cache?: { use?: boolean; cost?: number };
}): CostComponentFull {
  const { ppos, elements, copyPpoToCost, scrapRatePercent, cache } = props;
  if (copyPpoToCost) {
    return {
      fmc1cost: round2relaxed(ppos?.FMC1),
      fmc2cost: round2relaxed(ppos?.FMC2),
      fmc1ppo: round2relaxed(ppos?.FMC1),
      fmc2ppo: round2relaxed(ppos?.FMC2),
      fmc1variance: round2relaxed(ppos?.FMC1 === undefined ? undefined : 0),
      fmc2variance: round2relaxed(ppos?.FMC2 === undefined ? undefined : 0),
    };
  }

  if (cache?.use && cache.cost === undefined) {
    throw new CalculationError({
      region: 'US',
      message: 'Cost was provided but fmc2cost was missing',
    });
  }

  const cost = cache?.use
    ? cache.cost
    : Calculator.Elements.CC_ElementsScrap.Sum(elements, scrapRatePercent, { noRounding: true });

  return {
    fmc1cost: round2relaxed(cost),
    fmc2cost: round2relaxed(cost),
    fmc1ppo: round2relaxed(ppos?.FMC1),
    fmc2ppo: round2relaxed(ppos?.FMC2),
    fmc1variance: round2relaxed(Calculator.Common.Variance(ppos?.FMC1, cost)),
    fmc2variance: round2relaxed(Calculator.Common.Variance(ppos?.FMC2, cost)),
    _fmc1costRaw: cost,
    _fmc2costRaw: cost,
  };
}
