import { FLabel } from './FLabel';

interface FInputReadOnlyProps {
  label: string;
  value: React.ReactNode;
  blankDash?: boolean;
  align?: 'start' | 'center' | 'end';
}

export function FInputReadOnly(props: FInputReadOnlyProps) {
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {props.label && <FLabel label={props.label} />}
      <div
        style={{
          textAlign: props.align,
          width: '100%',
          whiteSpace: 'nowrap',
        }}
      >
        {!props.value && props.blankDash ? '—' : props.value}
      </div>
    </div>
  );
}
