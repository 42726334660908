import { DBBOM } from '@core/schemas/db/schema.db.elements';
import { OmitDBRecord } from '@core/schemas/db/schema.db.common';
import { now } from '@core/util/util.timestamp';

export const BOMExample: OmitDBRecord<DBBOM>[] = [
  {
    material_id: 123,
    bom_number: 123,
    status: 43,
    alternative: 1,
    base_uom: 'PCE',
    bom_type: 'DEVELOPMENT',
    materials: JSON.stringify([
      [1, 2, ''],
      [42, 8, ''],
    ]),
    timestamp: now(),
  },
];
