import { NCAccordion } from '@frontend/common/components/NCAccordion';
import { DataMaintenance } from '../../data-maintenance/components/DataMaintenance';
import { AccessManagement } from './AccessManagement';
import { CreateDeleteProduct } from './CreateDeleteProduct';
import { SimulateElementsCalculation } from './SimulateElementsCalculation';
import { ManipulateBOM } from './ManipulateBOM';
import { isProd } from '@frontend/common/lib/functions';
import { TestBOMConstruction } from './TestBOMConstruction';
import { MigrateData } from './MigrateData';
import { YearChangeContent } from './year-change/YearChangeContent';

export function AdministrationPage() {
  return (
    <div>
      <div style={{ margin: '12px 0 48px', fontSize: 30, fontWeight: 'bold', textAlign: 'center' }}>
        Administration
      </div>
      <NCAccordion
        sections={[
          { title: 'Access management', content: <AccessManagement />, defaultCollapsed: true },
          {
            title: 'Data maintenance',
            content: <DataMaintenance />,
            defaultCollapsed: true,
          },
          {
            title: 'Create/delete product',
            content: <CreateDeleteProduct />,
            defaultCollapsed: true,
            hide: isProd(),
          },
          {
            title: 'Manipulate BOM',
            content: <ManipulateBOM />,
            defaultCollapsed: true,
            hide: isProd(),
          },
          {
            title: 'Simulate elements calculation',
            content: <SimulateElementsCalculation />,
            defaultCollapsed: true,
            hide: isProd(),
          },
          {
            title: 'FMC year change',
            content: <YearChangeContent />,
            defaultCollapsed: true,
          },
          {
            title: 'Test BOM construction',
            content: <TestBOMConstruction />,
            defaultCollapsed: true,
          },
          {
            title: 'Migrate data',
            content: <MigrateData />,
            defaultCollapsed: true,
          },
        ]}
      />
    </div>
  );
}
