import React, { CSSProperties, useContext } from 'react';
import { useMemo } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useRef } from 'react';
import { ColumnHeader } from './columnHeader';
import { Row } from './row';
import { RowGrid } from './rowGrid';
import { getPropString, getSorter, LIGHT_GRAY, PasteChanges } from './lib';
import { SearchInput } from './searchInput';
import { Paginator } from './paginator';
import { TableSpinner, SyncSpinner } from './spinner';
import { useDisclosure } from '@lego/klik-ui';
import { NCModal } from '@frontend/common/components/NCModal';
import { ShownTableColumnsModalContent } from './ShownColumnsModelContent';
import { HiddenColumnsContext, UserDataContext } from '@frontend/common/lib/contexts';
import { useJumpToPage } from './useJumpToPage';
import { showInternalApplicationError } from '@frontend/common/lib/functions';

export interface TableProps<T extends object> {
  id: string;
  rows: T[];
  columns: TableColumn<T>[];
  rowKey: keyof T | ((row: T) => string);
  isRefreshing?: boolean;
  selectedRow?: T;
  multiSelection?: {
    selected: T[];
    setSelected: React.Dispatch<React.SetStateAction<T[]>>;
    disabled?(row: T): boolean;
  };
  searchProps?: {
    searchValue: string;
    setSearchValue(searchValue: string): void;
  };
  itemType?: string;
  className?: string;
  itemsPerPage?: number;
  addPaginationTop?: boolean;
  removePaginationBottom?: boolean;
  removeSearch?: boolean;
  removeInfoText?: boolean;
  headerContent?: React.ReactNode;
  stickyHeaderContainerHeight?: number;
  disableAutoScrollOnBottomPageChange?: boolean;
  removeLastRowBottomBorder?: boolean;
  stackHeaderTitles?: boolean;
  noDataText?: string;
  enableRowColoring?: boolean;
  disabledColumnFiltering?: boolean;
  paginateTo?: string;
  fixedHeight?: number;
  onPaste?(updates: PasteChanges<T>): void;
  onRowClick?:
    | ((clickedRow: T) => React.ReactNode | void)
    | ((clickedRow: T) => Promise<React.ReactNode | void>);
  onRefresh?(): void;
  rowHasError?(row: T): boolean;
  rowIsDisabled?(row: T): boolean;
}

export interface TableColumn<T extends object> {
  id?: string;
  title: string;
  // eslint-disable-next-line @typescript-eslint/ban-types
  dataIndex: keyof T | (string & {});
  groupName?: string;
  width?: 'min-content' | 'auto' | string;
  align?: 'start' | 'center' | 'end';
  titleAlign?: 'start' | 'center' | 'end';
  alignVertically?: 'start' | 'center' | 'end';
  sorted?: SortDirection;
  ignoreRowClick?: boolean;
  disableSorting?: boolean | SortDirection;
  stackTitle?: boolean;
  isActionColumn?: boolean;
  ignoreInExport?: boolean;
  hide?: boolean;
  pasteParseType?: 'string' | 'boolean' | 'number';
  disableChangeOnPaste?(row: T): boolean;
  style?(value: unknown, row: T, index: number, rows: T[]): CSSProperties | undefined;
  cellTitle?(value: unknown, row?: T, index?: number): string;
  sorter?(a: T, b: T): number;
  // render takes precendece so this can be used to define export value when render is used and dataIndex is missing
  format?(value: unknown, row: T, index: number, rows: T[]): string | number | boolean | unknown;
  render?(value: unknown, row: T, index: number, rows: T[]): React.ReactNode;
  headerRender?(value: string): React.ReactNode;
}

export type SortDirection = 'ascending' | 'descending';

export interface SortInfo {
  col: string;
  direction: SortDirection;
}

const DefaultPageSize = Number.MAX_SAFE_INTEGER;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getColumnId(column: TableColumn<any>) {
  return (
    column.id ||
    (column.groupName ? column.groupName + '_' : '') +
      String(column.dataIndex) +
      (column.isActionColumn ? '_action' : '')
  );
}

export function Table<T extends object>(props: TableProps<T>) {
  const [searchValue, setSearchValue] = useState('');
  const [sortInfo, setSortInfo] = useState<SortInfo>();
  const [currentPage, setCurrentPage] = useState(1);
  const [earlierRowsLength, setEarlierRowsLength] = useState(0);
  const [isTextSelected, setIsTextSelected] = useState(false);

  const earlierRowsAsString = useRef('');
  const stickyHeaderScrollContainer = useRef<HTMLDivElement | null>(null);

  const { isOpen, onClose, onOpen } = useDisclosure();
  const { getHiddenColumnIdsForId } = useContext(HiddenColumnsContext);
  const { userData } = useContext(UserDataContext);

  const hiddenColumnIds = useMemo(
    () => getHiddenColumnIdsForId(props.id),
    [getHiddenColumnIdsForId, props.id],
  );

  useEffect(() => {
    if (JSON.stringify(props.rows) === earlierRowsAsString.current) {
      return;
    }

    if (props.multiSelection && props.multiSelection.selected.length !== 0) {
      props.multiSelection.setSelected([]);
    }

    earlierRowsAsString.current = JSON.stringify(props.rows);
  }, [props.rows, props.multiSelection]);

  const visibleColumns = useMemo(() => {
    const preColumns = props.columns
      .filter((c) => !c.hide)
      .map((column) => ({
        ...column,
        sorter: column.sorter ? column.sorter : getSorter(column.dataIndex),
      }))
      .filter((col) => !hiddenColumnIds.includes(getColumnId(col)));

    // check if there exist a unique id for every column
    if (
      preColumns.reduce((set, col) => set.add(getColumnId(col)), new Set<string>()).size <
      preColumns.length
    ) {
      throw new Error(
        'Table error: Each column must be uniquely identiable by their dataIndex or id property',
      );
    }

    // check that either exportValue, format or dataIndex exists (used for export)
    if (preColumns.find((c) => c.dataIndex === '' && !c.format && !c.ignoreInExport)) {
      throw new Error(
        'Table error: Each column must either specify either dataIndex or format if ignoreInExport is not true',
      );
    }

    if (!preColumns.find((c) => c.width === 'auto')) {
      const indexOfColumnWithNoWidthSpecified = preColumns.findIndex((c) => c.width === undefined);
      if (indexOfColumnWithNoWidthSpecified !== -1) {
        preColumns[indexOfColumnWithNoWidthSpecified].width = 'auto';
      }
    }

    return preColumns;
  }, [props.columns, hiddenColumnIds]);

  useEffect(() => {
    let preSortInfo: SortInfo | undefined;

    visibleColumns.forEach((col) => {
      if (col.sorted) {
        if (preSortInfo) {
          throw new Error("Table error: At most one column may have its 'sorted' prop specified");
        }
        preSortInfo = { col: col.title, direction: col.sorted };
      }
    });

    setSortInfo(preSortInfo);
  }, [visibleColumns, props.rowKey]);

  const _innerTableId = useMemo(() => Math.round(Math.random() * 1000000).toString(), []);

  const sortedFilteredRows = useMemo(() => {
    const filteredRows = props.rows.filter((row) => {
      for (const col of visibleColumns) {
        const searchVal = props.searchProps
          ? props.searchProps.searchValue.toLowerCase()
          : searchValue.toLowerCase();
        const dataIndexVal = getPropString(row, String(col.dataIndex)).toLowerCase();

        if (dataIndexVal.includes(searchVal)) {
          return true;
        }
      }
      return false;
    });

    if (sortInfo) {
      const sortFunction = visibleColumns.find((col) => sortInfo.col === col.title)?.sorter;

      filteredRows.sort(sortFunction);

      if (sortInfo.direction === 'descending') {
        filteredRows.reverse();
      }
    }

    if (filteredRows.length !== earlierRowsLength) {
      setCurrentPage(1);
    }

    setEarlierRowsLength(filteredRows.length);

    return filteredRows;
  }, [props.rows, searchValue, visibleColumns, sortInfo, props.searchProps, earlierRowsLength]);

  const shownRows = useMemo(
    () =>
      sortedFilteredRows.slice(
        (currentPage - 1) * (props.itemsPerPage || DefaultPageSize),
        currentPage * (props.itemsPerPage || DefaultPageSize),
      ),
    [sortedFilteredRows, currentPage, props.itemsPerPage],
  );

  function getRowKey(r: T): string {
    return typeof props.rowKey === 'function'
      ? props.rowKey(r)
      : getPropString(r, String(props.rowKey));
  }

  useJumpToPage(
    {
      paginateTo: props.paginateTo,
      itemsPerPage: props.itemsPerPage,
      getRowKey,
      sortedFilteredRows,
    },
    setCurrentPage,
  );

  const numberOfPages = useMemo(
    () => Math.ceil(sortedFilteredRows.length / (props.itemsPerPage || DefaultPageSize)),
    [sortedFilteredRows, props.itemsPerPage],
  );

  const infoText = useMemo(() => {
    const itemsCount =
      sortedFilteredRows.length +
      ` ${props.itemType ?? 'item'}` +
      (sortedFilteredRows.length !== 1 ? 's' : '');

    const endIndex = currentPage * (props.itemsPerPage || DefaultPageSize);

    const shownCount =
      sortedFilteredRows.length === 0
        ? ''
        : numberOfPages === 1
        ? props.removeSearch
          ? ''
          : 'showing all'
        : `showing ${(currentPage - 1) * (props.itemsPerPage || DefaultPageSize) + 1}-${
            endIndex > sortedFilteredRows.length ? sortedFilteredRows.length : endIndex
          }`;

    const selectedCount =
      (props.multiSelection?.selected.length || 0) > 0
        ? `${props.multiSelection?.selected.length} selected`
        : '';

    return [itemsCount, shownCount, selectedCount].filter((s) => !!s).join(', ');
  }, [
    props.itemsPerPage,
    props.multiSelection,
    currentPage,
    numberOfPages,
    sortedFilteredRows,
    props.removeSearch,
    props.itemType,
  ]);

  const groupInfo: { groups: { name: string; span: string }[]; indicies: number[] } =
    useMemo(() => {
      if (visibleColumns.length === 0) {
        return { groups: [], indicies: [] };
      }

      const allGroups: { name: string; span: string }[] = [];
      const allIndicies: number[] = [];

      let start = 1; // grid is 1-indexed
      let span = 0;
      let lastGroupName = visibleColumns[0].groupName ?? '';

      for (let i = 0; i < visibleColumns.length; i++) {
        const colGroupName = visibleColumns[i].groupName ?? '';
        if (lastGroupName !== colGroupName) {
          allGroups.push({ name: lastGroupName, span: `${start} / span ${span}` });
          allIndicies.push(start - 1);

          span = 0;
          lastGroupName = colGroupName ?? '';
          start = i + 1;
        }

        span++;
      }

      allGroups.push({ name: lastGroupName, span: `${start} / span ${span}` });
      allIndicies.push(start - 1);

      if (allGroups.filter((g) => g.name).length === 0) {
        return { groups: [], indicies: [] };
      }

      return { groups: allGroups, indicies: allIndicies };
    }, [visibleColumns]);

  function onPaste(e: React.ClipboardEvent<HTMLDivElement>) {
    if (!props.onPaste) {
      return;
    }

    const table: HTMLDivElement = e.currentTarget;

    const htmlCells = table.querySelectorAll('[data-paste-index]');

    const editableCells = Array.from(htmlCells).filter((element) =>
      element.querySelector('[data-can-paste="true"]'),
    ) as HTMLElement[];

    const input: HTMLInputElement = e.target as HTMLInputElement;

    const parent = input.closest('.table-cell') as HTMLElement;

    if (!parent) {
      showInternalApplicationError();
      return;
    }

    const index = parent.dataset.pasteIndex?.split(',');

    if (!index || index.length !== 2) {
      showInternalApplicationError();
      return;
    }

    const v = e.clipboardData.getData('text').replaceAll('\r', '');
    const rows = v.split('\n');
    const cells = rows.map((r) => r.split('\t'));

    const startRow = Number(index[0]);
    const startCol = Number(index[1]);

    const affectedIds = shownRows.slice(startRow, startRow + rows.length);

    const affectedColumns = visibleColumns.slice(startCol, startCol + cells[0].length);

    const changes: PasteChanges<T> = affectedIds.map((row, rowIndex) => {
      const changes: Partial<
        // eslint-disable-next-line @typescript-eslint/ban-types
        Record<keyof T | (string & {}), number | string | boolean | undefined>
      > = {};

      affectedColumns.forEach((col, colIndex) => {
        const element = editableCells.find(
          (cell) => cell.dataset.pasteIndex === `${startRow + rowIndex},${startCol + colIndex}`,
        );

        if (!element) {
          return;
        }

        if (col.disableChangeOnPaste && col.disableChangeOnPaste(row)) {
          return;
        }

        let v: string | boolean | number | undefined = cells[rowIndex][colIndex];

        const regex = (element.querySelector('[data-regex]') as HTMLElement | undefined)?.dataset
          .regex;

        if (regex && !new RegExp(regex).test(v)) {
          return;
        }

        const disabledOptions = (
          element.querySelector('[data-disabled-options]') as HTMLElement | undefined
        )?.dataset.disabledOptions;

        if (disabledOptions && (JSON.parse(disabledOptions) as string[]).includes(v)) {
          return;
        }

        if (col.pasteParseType === 'boolean') {
          v = v === 'true' ? true : v === 'false' ? false : undefined;
        } else if (col.pasteParseType === 'number') {
          if (userData?.comma_as_decimal_seperator) {
            v = v.replaceAll(',', '.');
          }
          v = Number(v);
          if (isNaN(v)) {
            return;
          }
        }

        changes[col.dataIndex] = v;
      });

      return { row, changes };
    });

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    setTimeout(() => props.onPaste!(changes), 0);
  }

  return (
    <>
      <NCModal header="Shown table columns" isOpen={isOpen} onClose={onClose} maxWidth={800}>
        <ShownTableColumnsModalContent
          tableId={props.id}
          columns={props.columns.filter((c) => !c.isActionColumn)}
          hiddenColumnIds={hiddenColumnIds}
        />
      </NCModal>
      <div style={{ width: '100%' }} className="table-container" onPaste={onPaste}>
        {(props.onRefresh ||
          !props.removeInfoText ||
          (props.itemsPerPage && props.addPaginationTop) ||
          !props.removeSearch ||
          props.headerContent) && (
          <div
            style={{
              paddingBottom: 16,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginBottom: props.itemsPerPage && props.addPaginationTop ? -4 : undefined,
              ...(props.stickyHeaderContainerHeight
                ? {
                    position: 'sticky',
                    background: 'white',
                    top: '0px',
                    zIndex: 1,
                  }
                : {}),
            }}
            className="table-header"
          >
            <div style={{ display: 'flex', alignItems: 'end' }}>
              {!props.removeSearch && (
                <SearchInput
                  itemType={props.itemType}
                  value={props.searchProps ? props.searchProps.searchValue : searchValue}
                  onChange={(v) =>
                    props.searchProps ? props.searchProps.setSearchValue(v) : setSearchValue(v)
                  }
                />
              )}
              {!props.removeInfoText && (
                <div
                  style={{
                    marginLeft: props.removeSearch ? undefined : 24,
                    whiteSpace: 'nowrap',
                    marginBottom: props.removeSearch ? undefined : 4,
                  }}
                  className="table-infotext"
                >
                  {infoText}
                </div>
              )}
              {props.headerContent && (
                <div
                  style={{
                    marginLeft: !props.removeInfoText || !props.removeSearch ? 24 : undefined,
                  }}
                  className="table-header-customcontent-container"
                >
                  {props.headerContent}
                </div>
              )}
            </div>
            <div style={{ display: 'flex', alignItems: 'center', marginLeft: 24 }}>
              {props.onRefresh && (
                <SyncSpinner loading={!!props.isRefreshing} onClick={props.onRefresh} />
              )}
              {props.itemsPerPage && props.addPaginationTop && shownRows.length > 0 && (
                <Paginator
                  id={_innerTableId}
                  pageCount={numberOfPages}
                  currentPage={currentPage}
                  onPageChange={(page) => {
                    setCurrentPage(page);
                    stickyHeaderScrollContainer.current?.scrollTo(0, 0);
                  }}
                />
              )}
            </div>
          </div>
        )}
        <div
          style={{
            position: 'relative',
            borderTop: `1px solid ${LIGHT_GRAY}`,
            width: visibleColumns.reduce(
              (hasAutoWidth, col) => hasAutoWidth || col.width === 'auto',
              false,
            )
              ? undefined
              : 'min-content',
          }}
          className="table-row-container"
        >
          {props.isRefreshing && (
            <div
              style={{
                position: 'absolute',
                background: 'rgba(255,255,255,0.6)',
                height: '100%',
                width: '100%',
                zIndex: 100,
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginTop: shownRows.length === 1 ? 20 : 48,
                }}
              >
                <TableSpinner />
              </div>
            </div>
          )}
          <RowGrid
            columns={visibleColumns}
            fixedHeight={
              props.itemsPerPage && props.rows.length > props.itemsPerPage
                ? props.fixedHeight
                : undefined
            }
            isRefreshing={props.isRefreshing}
            multiSelection={props.multiSelection}
            stickyHeaderContainerHeight={props.stickyHeaderContainerHeight}
            innerRef={stickyHeaderScrollContainer}
          >
            {props.multiSelection && (
              <div
                style={{
                  paddingLeft: '10px',
                  display: 'flex',
                  justifyContent: 'center',
                  ...(!!props.stickyHeaderContainerHeight
                    ? {
                        position: 'sticky',
                        background: 'white',
                        top: '0px',
                        zIndex: 1,
                      }
                    : {}),
                  WebkitBoxSizing: 'border-box',
                  MozBoxSizing: 'border-box',
                  boxSizing: 'border-box',
                  height: '100%',
                  borderBottom: `2px solid ${LIGHT_GRAY}`,
                  cursor: 'pointer',
                }}
              >
                <input
                  type="checkbox"
                  checked={
                    props.rows.length !== 0 &&
                    props.multiSelection?.selected.length === props.rows.length
                  }
                  onChange={() => {
                    if (props.multiSelection?.selected.length === props.rows.length) {
                      props.multiSelection.setSelected([]);
                    } else {
                      props.multiSelection?.setSelected(props.rows);
                    }
                  }}
                />
              </div>
            )}

            {groupInfo.groups.map((g, i) => (
              <div
                key={i}
                style={{
                  gridColumn: g.span,
                  textAlign: 'center',
                  padding: '6px 12px 0',
                  color: !g.name ? 'rgba(0,0,0,0)' : undefined,
                  borderLeft:
                    i !== 0 && groupInfo.groups.length - 1 !== i ? '1px solid #e3e3e1' : undefined,
                  borderRight: i === groupInfo.groups.length - 2 ? '1px solid #e3e3e1' : undefined,
                  height: '100%',
                }}
                onClick={(e) => {
                  if (e.metaKey && !props.disabledColumnFiltering) {
                    onOpen();
                  }
                }}
              >
                {g.name || 'i'}
              </div>
            ))}

            {visibleColumns.map((col, i) => (
              <ColumnHeader
                key={i}
                column={col}
                onMetaClick={props.disabledColumnFiltering ? undefined : onOpen}
                sortInfo={sortInfo}
                setSortInfo={
                  col.disableSorting === true
                    ? undefined
                    : (sortInfo) => {
                        if (col.disableSorting === 'ascending') {
                          setSortInfo({ col: sortInfo.col, direction: 'descending' });
                        } else if (col.disableSorting === 'descending') {
                          setSortInfo({ col: sortInfo.col, direction: 'ascending' });
                        } else {
                          setSortInfo(sortInfo);
                        }
                      }
                }
                sticky={!!props.stickyHeaderContainerHeight}
                borderLeft={
                  i !== 0 &&
                  groupInfo.indicies[groupInfo.indicies.length - 1] !== i &&
                  groupInfo.indicies.includes(i)
                }
                borderRight={groupInfo.indicies[groupInfo.indicies.length - 1] === i + 1}
              />
            ))}
            {shownRows.length === 0 ? (
              <div
                style={{
                  gridColumn: `span ${visibleColumns.length + (props.multiSelection ? 1 : 0)}`,
                  margin: '24px',
                  fontSize: 16,
                }}
                className="table-nodataplaceholder"
              >
                {props.noDataText ?? `No ${(props.itemType ?? 'item') + 's'} to show...`}
              </div>
            ) : (
              shownRows.map((row, i) => (
                <Row
                  row={row}
                  rows={props.rows}
                  columns={visibleColumns}
                  enableRowColoring={!!props.enableRowColoring}
                  key={getRowKey(row)}
                  onMouseUp={() => {
                    const selection = window?.getSelection()?.toString();
                    setIsTextSelected((selection?.length || 0) > 0);
                    setTimeout(() => setIsTextSelected(false), 1);
                  }}
                  isSelected={props.selectedRow && getRowKey(props.selectedRow) === getRowKey(row)}
                  onRowClick={
                    props.onRowClick &&
                    (!props.rowIsDisabled || !props.rowIsDisabled(row)) &&
                    !isTextSelected
                      ? // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                        async () => await props.onRowClick!(row)
                      : undefined
                  }
                  renderBottomLine={i < shownRows.length - 1 || !props.removeLastRowBottomBorder}
                  getRowKey={getRowKey}
                  multiSelection={props.multiSelection}
                  isDisabled={!!props.rowIsDisabled && props.rowIsDisabled(row)}
                  hasError={props.rowHasError && props.rowHasError(row)}
                  index={i}
                />
              ))
            )}
          </RowGrid>
        </div>
        {props.itemsPerPage && !props.removePaginationBottom && shownRows.length > 0 && (
          <div
            style={{
              marginTop: '16px',
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <Paginator
              pageCount={numberOfPages}
              currentPage={currentPage}
              onPageChange={(v) => {
                setCurrentPage(v);
                if (!props.disableAutoScrollOnBottomPageChange) {
                  document
                    .getElementById(_innerTableId)
                    ?.scrollIntoView({ behavior: 'smooth', inline: 'nearest' });
                }
                stickyHeaderScrollContainer.current?.scrollTo(0, 0);
              }}
            />
          </div>
        )}
      </div>
    </>
  );
}
