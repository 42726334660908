import { ExportButton } from '@frontend/common/components/ExportButton';
import { Table } from '@frontend/table/table';
import {
  StatusIcon,
  YearChangeOverviewColumns,
  YearChangeOverviewExportColumns,
} from '../../administration.columns.am';
import { useState } from 'react';
import { NCModal } from '@frontend/common/components/NCModal';
import { YearChangeStatus } from '@core/schemas/db/schema.db.yearChange';
import { uppercaseFirstLetter } from '@core/util/util.uppercaseFirstLetter';
import { YearChangeOverviewRecord } from '@core/schemas/types/schemas.types';

interface YearChangeOverviewProps {
  yearChangeOverviewRecords: YearChangeOverviewRecord[];
  yearOptions: number[];
  loading: boolean;
  refresh(): void;
}

export function YearChangeOverview(props: YearChangeOverviewProps) {
  const [selectedRecord, setSelectedRecord] = useState<YearChangeOverviewRecord>();

  return (
    <div>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: 'min-content min-content auto',
          columnGap: 16,
          alignItems: 'center',
          rowGap: 6,
          marginBottom: 24,
        }}
      >
        <StatusIcon status={YearChangeStatus.Uknonwn} />
        <div style={{ fontWeight: 'bold' }}>{uppercaseFirstLetter(YearChangeStatus.Uknonwn)}</div>
        <div>Unable to find a latest approve revision in FMC year {new Date().getFullYear()}</div>

        <StatusIcon status={YearChangeStatus.Identified} />
        <div style={{ fontWeight: 'bold' }}>
          {uppercaseFirstLetter(YearChangeStatus.Identified)}
        </div>
        <div>Found a latest approved revision in FMC year {new Date().getFullYear()}</div>

        <StatusIcon status={YearChangeStatus.IdentifiedNotApproved} />
        <div style={{ fontWeight: 'bold', whiteSpace: 'nowrap' }}>
          {uppercaseFirstLetter(YearChangeStatus.IdentifiedNotApproved)}
        </div>
        <div>Found a latest non-approved revision in FMC year {new Date().getFullYear()}</div>

        <StatusIcon status={YearChangeStatus.Error} />
        <div style={{ fontWeight: 'bold' }}>{uppercaseFirstLetter(YearChangeStatus.Error)}</div>
        <div>
          New revision created in FMC year {new Date().getFullYear() + 1} but contains errors
        </div>

        <StatusIcon status={YearChangeStatus.Updated} />
        <div style={{ fontWeight: 'bold' }}>{uppercaseFirstLetter(YearChangeStatus.Updated)}</div>
        <div>New revision created in FMC year {new Date().getFullYear() + 1}</div>

        <StatusIcon status={YearChangeStatus.Approved} />
        <div style={{ fontWeight: 'bold' }}>{uppercaseFirstLetter(YearChangeStatus.Approved)}</div>
        <div>New revision in FMC year {new Date().getFullYear() + 1} approved</div>
      </div>
      <Table
        id="year-change_overview"
        rowKey="productId"
        headerContent={
          <ExportButton
            rows={props.yearChangeOverviewRecords}
            columns={YearChangeOverviewExportColumns}
          />
        }
        onRowClick={setSelectedRecord}
        columns={YearChangeOverviewColumns}
        rows={props.yearChangeOverviewRecords}
        isRefreshing={props.loading}
        onRefresh={props.refresh}
        itemsPerPage={50}
        addPaginationTop
      />
      <NCModal
        isOpen={!!selectedRecord}
        onClose={() => setSelectedRecord(undefined)}
        header="Year change record"
        maxWidth={1200}
      >
        {selectedRecord && (
          <div>
            <div style={{ marginBottom: 8 }}>
              <b>Product ID:</b> {selectedRecord.productId}
            </div>

            <div
              style={{
                display: 'grid',
                gridTemplateColumns: 'min-content min-content min-content auto',
                columnGap: 16,
                rowGap: 8,
                alignItems: 'center',
              }}
            >
              <div style={{ fontWeight: 'bold' }}>Screen</div>
              <div style={{ fontWeight: 'bold' }}>Status</div>
              <div style={{ fontWeight: 'bold' }}>Revision</div>
              <div style={{ fontWeight: 'bold' }}>Message</div>
              <div>Elements</div>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <StatusIcon status={selectedRecord.elements.status} />
              </div>
              <div style={{ textAlign: 'end' }}>{selectedRecord?.elements.revision}</div>
              <div style={{ whiteSpace: 'nowrap' }}>{selectedRecord?.elements.message}</div>
              <div>Packaging</div>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <StatusIcon status={selectedRecord.packaging.status} />
              </div>
              <div style={{ textAlign: 'end' }}>{selectedRecord?.packaging.revision}</div>
              <div style={{ whiteSpace: 'nowrap' }}>{selectedRecord?.packaging.message}</div>
              <div>BI</div>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <StatusIcon status={selectedRecord.bi.status} />
              </div>
              <div style={{ textAlign: 'end' }}>{selectedRecord?.bi.revision}</div>
              <div style={{ whiteSpace: 'nowrap' }}>{selectedRecord?.bi.message}</div>
              <div style={{ whiteSpace: 'nowrap' }}>Final costs</div>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <StatusIcon status={selectedRecord.finalcosts.status} />
              </div>
              <div style={{ textAlign: 'end' }}>{selectedRecord?.finalcosts.revision}</div>
              <div style={{ whiteSpace: 'nowrap' }}>{selectedRecord?.finalcosts.message}</div>
            </div>
          </div>
        )}
      </NCModal>
    </div>
  );
}
