import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Spacer,
} from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';

interface NCAccordionProps {
  sections: {
    title: string;
    content: React.ReactNode;
    hide?: boolean;
    noBottomMargin?: boolean;
    defaultCollapsed?: boolean;
  }[];
}

export function NCAccordion(props: NCAccordionProps) {
  const [openedIndicies, setOpenedIndicies] = useState<number[]>([]);
  const hasSetIndicies = useRef(false);

  useEffect(() => {
    if (hasSetIndicies.current) {
      return;
    }
    hasSetIndicies.current = true;
    setOpenedIndicies(
      props.sections
        .filter((s) => !s.hide)
        .map((s, i) => ({ section: s, index: i }))
        .filter((s) => !s.section.defaultCollapsed)
        .map((s) => s.index),
    );
  }, [props.sections]);

  return (
    <Accordion
      onChange={(v) => {
        if (!Array.isArray(v)) {
          return;
        }
        setOpenedIndicies(v);
      }}
      defaultIndex={props.sections
        .map((s, i) => ({ ...s, i }))
        .filter((s) => !s.defaultCollapsed)
        .map((s) => s.i)}
      allowMultiple
    >
      {props.sections
        .filter((sec) => !sec.hide)
        .map((section, i) => (
          <AccordionItem key={section.title}>
            <AccordionButton>
              {section.title}
              <Spacer />
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel marginBottom={section.noBottomMargin ? undefined : '48px'}>
              {openedIndicies.includes(i) && section.content}
            </AccordionPanel>
          </AccordionItem>
        ))}
    </Accordion>
  );
}
