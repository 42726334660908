import { AdministrationYearChangeClearDataEndpoint } from '@core/schemas/endpoint/schema.endpoint.administration';
import { REGEX_NONNEGATIVE_INTEGER_OR_EMPTY } from '@core/types/types.regex';
import { callEndpoint } from '@frontend/common/lib/callEndpoint';
import { showSuccessToast } from '@frontend/common/lib/functions';
import { FInput } from '@frontend/form/components/FInput';
import { Button } from '@lego/klik-ui';
import { useState } from 'react';

interface YearChangeClearDataProps {
  refresh(): void;
}

export function YearChangeClearData(props: YearChangeClearDataProps) {
  const [productId, setProductId] = useState('');
  const [clearSingleLoading, setClearSingleLoading] = useState(false);
  const [clearAllLoading, setClearAllLoading] = useState(false);

  async function clearData(all?: 'ALL') {
    if (!all && !productId) {
      return;
    }

    const [err] = await callEndpoint({
      endpoint: AdministrationYearChangeClearDataEndpoint,
      input: { target: all || Number(productId) },
      errorHandling: { header: 'Clearing data' },
    });

    if (err) {
      return;
    }

    props.refresh();
    showSuccessToast(
      'Clear data',
      `Successfully cleared data in ${new Date().getFullYear() + 1}${
        all ? '' : ` for product ${productId}`
      }`,
    );
  }

  return (
    <div>
      <div style={{ display: 'grid', rowGap: 8, width: 200 }}>
        <div style={{ display: 'flex', columnGap: 8, alignItems: 'end' }}>
          <FInput
            placeholder="Product ID"
            editable
            align="start"
            regex={REGEX_NONNEGATIVE_INTEGER_OR_EMPTY}
            disallowChangeOnInvalid
            disableSmartBehavior
            value={productId}
            disableBlurOnEnter
            onChange={setProductId}
            onEnter={async () => {
              setClearSingleLoading(true);
              await clearData();
              setClearSingleLoading(false);
            }}
            style={{ width: 'inherit' }}
          />
          <Button
            isLoading={clearSingleLoading}
            colorScheme="error"
            disabled={!productId}
            onClick={async () => {
              setClearSingleLoading(true);
              await clearData();
              setClearSingleLoading(false);
            }}
            size="sm"
          >
            Clear
          </Button>
        </div>
        <div style={{ textAlign: 'center' }}>OR</div>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            isLoading={clearAllLoading}
            size="sm"
            colorScheme="error"
            onClick={async () => {
              setClearAllLoading(true);
              await clearData('ALL');
              setClearAllLoading(false);
            }}
          >
            Clear all
          </Button>
        </div>
      </div>
    </div>
  );
}
