import { z } from 'zod';
import { DBBICostSchema, DBBIRateSchema, DBProdHeadBISchema } from '@core/schemas/db/schema.db.bi';
import { defineAMMAEndpoint, defineEndpoint } from '@core/util/util.defineEndpoint';
import {
  OMIT_DB_RECORD,
  OMIT_DB_RECORD_EXCEPT_PK,
} from '@core/schemas/const/schema.const.OMIT_DB_RECORD';
import { RegionEnum, RevisionErrorSchema } from '../schema.common';
import { RevisionStatus } from '@core/types/types.reporting';
import { notEmpty } from '../schema.util';

// BIGetEndpoint
const BIGetResponseSchema = z.object({
  prodHead: DBProdHeadBISchema.omit(OMIT_DB_RECORD_EXCEPT_PK),
  erectCode: z.string().optional(),
  description: z.string(),
  maxRevision: z.number().int(),
  biCosts: z.array(
    DBBICostSchema.extend({ description: z.string() }).omit({
      ...OMIT_DB_RECORD,
      product_id: true,
      revision: true,
    }),
  ),
  productCosts: z.object({
    ppo: z.object({
      FMC1: z.number().optional(),
      FMC2: z.number().optional(),
    }),
  }),
});

export type BIGetEndpointInput = z.infer<typeof BIGetEndpoint.input>;
export type BIGetEndpointResponse = z.infer<typeof BIGetEndpoint.response>;
export const BIGetEndpoint = defineEndpoint({
  path: '/bi/get',
  input: z.object({
    productId: z.number().int(),
    rev: z.number().int().optional(),
  }),
  response: BIGetResponseSchema,
});

export type BINewGetEndpointInput = z.infer<typeof BINewGetEndpoint.input>;
export type BINewGetEndpointResponse = z.infer<typeof BINewGetEndpoint.response>;
export const BINewGetEndpoint = defineEndpoint({
  path: '/bi/new/get',
  input: z.object({
    productId: z.number().int(),
  }),
  response: BIGetResponseSchema,
});

export type BISaveEndpointInput = z.infer<typeof BISaveEndpoint.input>;
export type BISaveEndpointResponse = z.infer<typeof BISaveEndpoint.response>;
export const BISaveEndpoint = defineEndpoint({
  path: '/bi/save',
  mutation: true,
  input: z.object({
    productId: z.number().int(),
    revision: z.union([z.number().int(), z.literal('NEW')]),
    books: z.array(
      DBBICostSchema.omit({
        ...OMIT_DB_RECORD,
        product_id: true,
        revision: true,
        cost: true,
      }).merge(z.object({ _manualCost: z.number() })),
    ),
    biStatus: z.number().int(),
    forceBiStatus: z.boolean().optional(),
  }),
  response: z.object({
    savedRevision: z.number().int(),
    revisionErrors: z.array(RevisionErrorSchema).optional(),
  }),
});

export type BIRatesListEndpointInput = z.infer<typeof BIRatesListEndpoint.input>;
export type BIRatesListEndpointResponse = z.infer<typeof BIRatesListEndpoint.response>;
export const BIRatesListEndpoint = defineEndpoint({
  path: '/bi/rates-list',
  input: z.object({
    year: z.number().int(),
  }),
  response: z.array(DBBIRateSchema.extend({ description: z.string() })),
});

export type BIRatesGetEndpointInput = z.infer<typeof BIRatesGetEndpoint.input>;
export type BIRatesGetEndpointResponse = z.infer<typeof BIRatesGetEndpoint.response>;
export const BIRatesGetEndpoint = defineEndpoint({
  path: '/bi/rates-get',
  input: z.object({
    year: z.number().int(),
    superDesign: z.number().int(),
    pages: z.number().int(),
    coverPages: z.number().int(),
    colorsFrontside: z.number().int(),
    colorsBackside: z.number().int(),
    paperWeight: z.number().int(),
    coverWeight: z.number().int(),
  }),
  response: DBBIRateSchema.extend({ description: z.string() }),
});

export type BIRatesGetSmartEndpointInput = z.infer<typeof BIRatesGetSmartEndpoint.input>;
export type BIRatesGetSmartEndpointResponse = z.infer<typeof BIRatesGetSmartEndpoint.response>;
export const BIRatesGetSmartEndpoint = defineEndpoint({
  path: '/bi/rates-get-smart',
  input: z.object({
    year: z.number().int(),
    superDesign: z.number().int(),
    pages: z.number().int(),
  }),
  response: z.array(DBBIRateSchema.extend({ description: z.string() })),
});

export type BIApproveEndpointInput = z.infer<typeof BIApproveEndpoint.input>;
export type BIApproveEndpointResponse = z.infer<typeof BIApproveEndpoint.response>;
export const BIApproveEndpoint = defineEndpoint({
  path: '/bi/approve',
  mutation: true,
  input: z.object({
    productId: z.number().int(),
    revision: z.number().int(),
    _unapprove: z.boolean().optional(),
  }),
  response: z.object({
    approved: z.boolean(),
  }),
});

export type BIReportEndpointQueryInput = z.infer<typeof BIReportEndpoint.queryInput>;
export type BIReportEndpointResponse = z.infer<typeof BIReportEndpoint.response>;
export const BIReportEndpoint = defineAMMAEndpoint({
  method: 'POST',
  path: '/v1/report/bi',
  bodyInput: z
    .object({
      productIds: z.array(z.number()).optional(),
      productIdRanges: z.array(z.object({ from: z.number(), to: z.number() })).optional(),
      year: z
        .object({ from: z.number().int().optional(), to: z.number().int().optional() })
        .refine(...notEmpty())
        .optional(),
    })
    .refine(...notEmpty())
    .openapi('BI-report-body-input'),
  response: z
    .array(
      z.object({
        productId: z.number(),
        description: z.string(),
        superTheme: z.string(),
        erectedSizeCode: z.string(),
        revision: z.number(),
        changedTimestamp: z.string(),
        changedBy: z.string(),
        year: z.number(),
        revisionStatus: z.nativeEnum(RevisionStatus),
        biStatus: z.string(),
        bomStatus: z.number(),
        biQrCode: z.union([z.literal('Yes'), z.literal('No')]),
        bookNumber: z.number(),
        fmc1superDesign: z.number().optional(),
        fmc1description: z.string(),
        fmc1pages: z.number(),
        fmc1region: RegionEnum,
        fmc1colorsFront: z.number(),
        fmc1colorsBack: z.number(),
        fmc1coverPages: z.number(),
        fmc1paperWeight: z.number(),
        fmc1coverWeight: z.number(),
        fmc1cost: z.number(),
        fmc1note: z.string(),
        fmc2superDesign: z.number().optional(),
        fmc2description: z.string(),
        fmc2pages: z.number(),
        fmc2region: RegionEnum,
        fmc2colorsFront: z.number(),
        fmc2colorsBack: z.number(),
        fmc2coverPages: z.number(),
        fmc2paperWeight: z.number(),
        fmc2coverWeight: z.number(),
        fmc2cost: z.number(),
        fmc2note: z.string(),
      }),
    )
    .openapi('BI-report-response'),
});
