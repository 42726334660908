import { PK } from '@core/types/types.pk';
import { DBRecordSchema } from './schema.db.common';
import { z } from 'zod';
import { createDescription } from '@core/util/util.schemaPropDescription';

export enum YearChangeStatus {
  Uknonwn = 'UNKNOWN',
  Identified = 'IDENTIFIED',
  IdentifiedNotApproved = 'IDENTIFIED_NOT_APPROVED',
  Error = 'ERROR',
  Updated = 'UPDATED',
  Approved = 'APPROVED',
}

export type DBYearChangeRecord = z.infer<typeof DBYearChangeRecordSchema>;
export const DBYearChangeRecordSchema = DBRecordSchema.extend({
  PK: z.literal(PK.YearChangeRecord),
  year: z
    .number()
    .int()
    .describe(createDescription({ isPartOfKey: true })),
  product_id: z
    .number()
    .int()
    .describe(createDescription({ isPartOfKey: true })),
});
