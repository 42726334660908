import { DBRecordSchema } from '@core/schemas/db/schema.db.common';
import { PK } from '@core/types/types.pk';
import { createDescription } from '@core/util/util.schemaPropDescription';
import { z } from 'zod';

export type DBUserSettings = z.infer<typeof DBUserSettingsSchema>;
export const DBUserSettingsSchema = DBRecordSchema.extend({
  PK: z.literal(PK.UserSettings),
  email: z.string().describe(createDescription({ isPartOfKey: true })),
  name: z.string(),
  comma_as_decimal_seperator: z.boolean(),
});
