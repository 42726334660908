import { useEndpoint } from '@frontend/common/lib/hooks/useEndpoint';
import {
  AdministrationYearChangeOverviewEndpoint,
  AdministrationYearChangeYearOptionsEndpoint,
} from '@core/schemas/endpoint/schema.endpoint.administration';
import { Tabs } from '@lego/klik-ui';
import { YearChangeActions } from './YearChangeActions';
import { YearChangeOverview } from './YearChangeOverview';
import { SetActiveFmcYear } from './SetActiveFmcYear';
import { YearChangeUpdateVOPeriod } from './YearChangeUpdateVOPeriod';
import { isProd } from '@frontend/common/lib/functions';
import { YearChangeClearData } from './YearChangeClearData';

export function YearChangeContent() {
  const { data: yearOptions } = useEndpoint({
    endpoint: AdministrationYearChangeYearOptionsEndpoint,
    input: {},
    errorHandling: { header: 'Fetching year options' },
  });
  const {
    data,
    loading,
    queryFunction: fetchOverview,
  } = useEndpoint({
    endpoint: AdministrationYearChangeOverviewEndpoint,
    input: { calendarYear: new Date().getFullYear() },
    errorHandling: { header: 'Fetching year change overview' },
  });

  return (
    <div>
      <Tabs>
        <Tabs.TabList>
          <Tabs.Tab className="tab-header">Overview</Tabs.Tab>
          <Tabs.Tab className="tab-header">Actions</Tabs.Tab>
          <Tabs.Tab className="tab-header">Change active FMC year</Tabs.Tab>
          <Tabs.Tab className="tab-header">Change VO period</Tabs.Tab>
          {!isProd() && (
            <Tabs.Tab className="tab-header">Clear data in {new Date().getFullYear() + 1}</Tabs.Tab>
          )}
        </Tabs.TabList>
        <Tabs.TabPanels>
          <Tabs.TabPanel>
            <YearChangeOverview
              yearOptions={yearOptions || []}
              loading={loading}
              refresh={fetchOverview}
              yearChangeOverviewRecords={data || []}
            />
          </Tabs.TabPanel>
          <Tabs.TabPanel>
            <YearChangeActions refreshOverview={fetchOverview} years={yearOptions || []} />
          </Tabs.TabPanel>
          <Tabs.TabPanel>
            <SetActiveFmcYear />
          </Tabs.TabPanel>
          <Tabs.TabPanel>
            <YearChangeUpdateVOPeriod />
          </Tabs.TabPanel>
          {!isProd() && (
            <Tabs.TabPanel>
              <YearChangeClearData refresh={fetchOverview} />
            </Tabs.TabPanel>
          )}
        </Tabs.TabPanels>
      </Tabs>
    </div>
  );
}
